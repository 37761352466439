import React, {Component} from 'react';
import NavBar from "../components/NavBar";
import ChatBox from "../components/ChatBox";
import Axios from '../utils/Axios';
import ViewContainer from './ViewContainer';

class ChatbotView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      is_live: true,

      app: props.app,
      extra_classes: props.extra_classes,
      client_lead_id: props.client_lead_id,
      client_name: props.client_name,
    };
  }

  render()
  {
    const _this = this;

    const {is_live, client_name, client_lead_id, loggedin_user_id} = _this.state;
    const chatbox_height = (_this.props.chat_config != null) ? _this.props.chat_config.chatbox_height : "100%";

    if(client_lead_id != null)
    {
      return (
        <React.Fragment>
            <NavBar />
              <ChatBox is_live={is_live}
                       extra_classes={_this.state.extra_classes}
                       client_name={client_name}
                       client_lead_id={client_lead_id}
                       chat_config={_this.props.chat_config}/>
      </React.Fragment>
      );
    }
  }

};

export default ChatbotView;
