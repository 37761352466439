
import React, { Component } from 'react';
import { Button, Form, Container, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import ASComponent from './ASComponent';
import Axios from '../utils/Axios';
import base64 from 'base-64';

import LLCalendar from './LLCalendar.js';

class Appointments extends ASComponent
{
    constructor(props)
    {
        super(props);

        const loggedin_user = props.app.state.loggedin_user;
        var client_id = null;
        if(loggedin_user != null)
        {
            client_id = loggedin_user.client_id;
        }

        this.state =
        {
          client_id,
          pagination: 0,
          is_test: props.is_test
        };

        this.handle_file_change = this.handle_file_change.bind(this);
    }

    async componentDidMount(event)
    {
      const view = this;

      const { is_test, pagination, client_id } = this.state;

      const params = '?page=' + pagination + "&client_id=" + client_id;
      const url = "/api/appointments/";

      await Axios.Get(url, params, async(response)=>
      {
        const array_data = response.data;
        if(array_data != null)
        {
          await view.setState({...view.state, ready: true});

          var appointments = array_data.data;
          if(appointments != null)
          {
              var decoded_appointments = [];
              for(var i=0; i < appointments.length; ++i)
              {
                var appointment = appointments[i];
                const db_id = appointment.id;

                var decoded = base64.decode(appointment.json_data);
                if(decoded != null)
                {
                  decoded = JSON.parse(decoded);
                  appointment =
                  {
                    ...appointment,
                    ...decoded
                  };

                  appointment.db_id = db_id;
                  appointment.start_date = new Date(appointment.start.dateTime);
                  appointment.end_date = new Date(appointment.end.dateTime);

                  delete appointment.json_data;
                  decoded_appointments.push(appointment);

                  if(i == (appointments.length-1))
                  {
                    await view.setState({...view.state, appointments: decoded_appointments});
                  }
                }
              }
          }
        }
      });
    }

    handle_file_change(event)
    {
      const view = this;


    }


    render()
    {
      const view = this;

      const {is_test, ready, appointments} = view.state;
      if(ready)
      {
        return (
            <Container className="data_source-upload-container">
                <h1 className="appointments-title">Appointments</h1>
                <LLCalendar appointments={appointments}/>
            </Container>
        );
      }
    }
}

export default Appointments;
