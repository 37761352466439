import React from 'react';
import ASComponent from './ASComponent';
import AppointmentModal from '../views/modals/AppointmentModal.js';

import './calendar-test/calendar.css'
import { v4 as uuidv4 } from 'uuid';

import { FaBeer } from "react-icons/fa";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const c_todays_date = new Date();

class LLCalendar extends ASComponent
{
  constructor(props)
  {
    super(props);

    this.weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.months = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];

    this.state =
    {
      appointments: props.appointments,
      current_day: new Date(),
      todays_date: new Date(),
      mode: "month",
      locale: "en-US",
      show_modal: false,
      selected_appointment: null
    }

    this.show_modal = this.show_modal.bind(this);

    this.day_mode = this.day_mode.bind(this);

    this.update_current_day = this.update_current_day.bind(this);
    this.on_today_button_click = this.on_today_button_click.bind(this);
    this.on_prev_click = this.on_prev_click.bind(this);
    this.on_next_click = this.on_next_click.bind(this);
    this.on_mode_change = this.on_mode_change.bind(this);
    this.on_calendar_event_click = this.on_calendar_event_click.bind(this);
    this.on_calendar_event_click = this.on_calendar_event_click.bind(this);

    this.find_appointment = this.find_appointment.bind(this);

    this.get_time_rows = this.get_time_rows.bind(this);
    this.get_day_events = this.get_day_events.bind(this);

    this.render_prev_button = this.render_prev_button.bind(this);
    this.render_next_button = this.render_next_button.bind(this);
    this.render_today_button = this.render_today_button.bind(this);
    this.render_mode_dropdown = this.render_mode_dropdown.bind(this);

    this.render_day = this.render_day.bind(this);
    this.render_day_events = this.render_day_events.bind(this);

    this.render_time_row_app1_events = this.render_time_row_app1_events.bind(this);
    this.render_time_row_app2_events = this.render_time_row_app2_events.bind(this);
    this.render_time_row_days = this.render_time_row_days.bind(this);
    this.render_time_row = this.render_time_row.bind(this);


    this.render_week_days_header = this.render_week_days_header.bind(this);
    this.render_week = this.render_week.bind(this);

    this.render_month = this.render_month.bind(this);
    this.render_month_day_events = this.render_month_day_events.bind(this);
    this.render_month_days = this.render_month_days.bind(this);


    this.render_day_title = this.render_day_title.bind(this);
    this.render_week_title = this.render_week_title.bind(this);
    this.render_month_title = this.render_month_title.bind(this);
    this.render_calendar_header = this.render_calendar_header.bind(this);

    this.render_calendar_header = this.render_calendar_header.bind(this);
    this.render_modal = this.render_modal.bind(this);
  }

  show_modal(appointment)
  {
    const view = this;
    view.setState({...view.state, show_modal: true});
  }

  day_mode(day)
  {
    const _this = this;
    _this.setState({..._this.state, mode: "day", current_day: new Date(day.year, day.month, day.number) });
  }

  update_current_day(day)
  {
    const _this = this;
    _this.setState({..._this.state,  current_day: new Date(day.year, day.month, day.number) });
  }

  on_close_modal()
  {
    const view = this;
    view.setState({...view.state, show_modal: false, selected_appointment: null});
  }

  on_today_button_click(e)
  {
    e.preventDefault();

    const _this = this;
    _this.setState({ current_day: new Date() });
  }

  on_prev_click(e)
  {
    e.preventDefault();

    const _this = this;

    const {current_day, mode} = this.state;

    if(current_day != null)
    {
      // console.log("Back!")
      if(mode == "day")
      {
        const day = current_day.getDate() - 1;
        current_day.setDate(day);
        this.setState({..._this.state, current_day});
      }
      else
      if(mode == "week")
      {
        const day = current_day.getDate() - 7;
        current_day.setDate(day);
        this.setState({..._this.state, current_day});
      }
      else
      if(mode == "month")
      {
        const month = current_day.getMonth();
        // console.log(month);
        current_day.setMonth(month - 1);
        this.setState({..._this.state, current_day});
      }
    }
  }

  on_next_click(e)
  {
    e.preventDefault();

    const _this = this;

    const {current_day, mode} = this.state;

    if(current_day != null)
    {
      // console.log("Forward!")
      if(mode == "day")
      {
        const day = current_day.getDate() + 1;
        current_day.setDate(day);
        this.setState({..._this.state, current_day});
      }
      else
      if(mode == "week")
      {
        const day = current_day.getDate() + 7;
        current_day.setDate(day);
        this.setState({..._this.state, current_day});
      }
      else
      if(mode == "month")
      {
        const month = current_day.getMonth();
        // console.log(month);
        current_day.setMonth(month + 1);
        this.setState({..._this.state, current_day});
      }

    }
  }

  on_mode_change(e)
  {
    const _this = this;

    e.preventDefault();

    const mode = e.target.value;
    this.setState({..._this.state, mode});
  }

  on_calendar_event_click(e)
  {
    const _this = this;

    e.preventDefault();

    const db_id = e.target.getAttribute('id');

    if(db_id != null)
    {
      const appointment = _this.find_appointment(db_id);
      if(appointment != null)
      {
        console.log("on_calendar_event_click!");
        _this.setState({..._this.sate, selected_appointment: appointment}, ()=>
        {
          _this.show_modal();
        })
      }
    }
  }

  find_appointment(id)
  {
    const _this = this;

    const {appointments} = _this.state;

    for(var i=0; i < appointments.length; ++i)
    {
      const appointment = appointments[i];
      if(appointment.db_id == id)
      {
        return appointment;
      }
    }
    return null;
  }

  get_time_rows(start_time, end_time, num_days)
  {
    const _this = this;

    const {current_day, locale, mode} = this.state;

    var time_rows = [];
    let time = 0;
    for(let time = start_time; time < end_time; ++time)
    {
      var time_row = {};
      var time_label = "00";
      if((time < 10) && (time > 0))
      {
        time_label = "0" + time;
      }
      else
      {
          time_label = time;
      }

      if(time_label == '0')
      {
        time_label = '00';
      }
      time_label += ':00';

      const day_time =
      {
        time: time_label,
        index: time
      }
      time_row.day_time = day_time;

      time_row.days = [];
      var day_of_week = new Date(current_day);
      const weekday_of_first_day = day_of_week.getDay();
      if(mode == "month")
      {
        while(day_of_week.getDay() != 2)
        {
          const day = day_of_week.getDate() - 1;
          day_of_week.setDate(day);
        }

        day_of_week.setDate(day_of_week.getDate() + 1);
      }

      for(let day = 0; day < num_days; ++day)
      {
        const date = day_of_week.getDate();
        if(mode != "day")
        {
          if(day === 0 && weekday_of_first_day === 0)
          {
            day_of_week.setDate(date - 7);
          }
          else
          if(day === 0)
          {
            day_of_week.setDate(date + (day - weekday_of_first_day));
          }
          else
          {
            day_of_week.setDate(date + 1);
          }
        }

        let calendar_day =
        {
          currentMonth: (day_of_week.getMonth() === current_day.getMonth()),
          date: (new Date(day_of_week)),
          month: day_of_week.getMonth(),
          number: day_of_week.getDate(),
          day_name:  day_of_week.toLocaleDateString(locale, { weekday: 'short' }),
          selected: (day_of_week.toDateString() === current_day.toDateString()),
          year: day_of_week.getFullYear()
        }

        time_row.days.push(calendar_day);
      }
      time_rows.push(time_row);
    }
    return time_rows;
  }

  get_day_events(day)
  {
    const _this = this;

    const {appointments} = _this.state;

    var events = [];
    if(appointments != null)
    {
      const day_date = day.getDate();
      const day_day = day.getDay();
      const day_month = day.getMonth();
      const day_year = day.getYear();

      for(var i=0; i < appointments.length; ++i)
      {
        const appointment = appointments[i];

        const appt_year = appointment.start_date.getYear();
        if(appt_year == day_year)
        {
          const appt_month = appointment.start_date.getMonth();
          if(appt_month == day_month)
          {
            const appt_date = appointment.start_date.getDate();
            const appt_day = appointment.start_date.getDay();
            if((appt_date == day_date) && (appt_day == day_day))
            {
              events.push(appointment);
            }
          }
        }
      }
    }
    return events;
  }

  render_prev_button()
  {
    const _this = this;
    return <MdArrowBackIos className="prev_next_button" style={{marginLeft: "20px"}} onClick={_this.on_prev_click} />
  }

  render_next_button()
  {
    const _this = this;
    return <MdArrowForwardIos className="prev_next_button day_next_button" onClick={_this.on_next_click} />
  }

  render_today_button()
  {
    const _this = this;
    return <button className="today_button" onClick={_this.on_today_button_click}>Today</button>
  }

  render_month_day_events(day)
  {
    const _this = this;

    const events = day.events;

    return (
      <div className="">
        {
          events.map((event, index) =>
          {
            var summary = event.summary;
            summary = summary.replaceAll("Lead Lazarus -", "");

            return (
              <div id={index} data={events[index]} key={index}>
                <p className="event_summary"
                   id={event.db_id}
                   onClick={_this.on_calendar_event_click}>{summary}</p>
              </div>
            )
          })
        }
      </div>
    )
  }

  render_month_days()
  {
    const _this = this;

    const {current_day} = this.state;

    if(current_day != null)
    {
      const first_day_of_month = new Date(current_day.getFullYear(), current_day.getMonth(), 1);
      const weekday_of_first_day = first_day_of_month.getDay();
      let days = [];

      for (let day = 0; day < 42; day++)
      {
        const date = first_day_of_month.getDate();
        if(day === 0 && weekday_of_first_day === 0)
        {
          first_day_of_month.setDate(date - 6);
        }
        else
        if(day === 0)
        {
          first_day_of_month.setDate(date + (day - weekday_of_first_day + 1));
        }
        else
        {
          first_day_of_month.setDate(date + 1);
        }

        let calendar_day =
        {
          currentMonth: (first_day_of_month.getMonth() === current_day.getMonth()),
          date: (new Date(first_day_of_month)),
          month: first_day_of_month.getMonth(),
          number: first_day_of_month.getDate(),
          selected: (first_day_of_month.toDateString() === current_day.toDateString()),
          year: first_day_of_month.getFullYear()
        }

        calendar_day.events = _this.get_day_events(calendar_day.date);

        days.push(calendar_day);
      }

      return (
        <div className="table-content">
          {
            days.map((day, index) =>
            {
              const current_month = day.currentMonth ? " current" : "";
              const day_selected =  day.selected ? " selected" : "";

              return (
                <div id={index} data={days[index]} key={index}
                      className={"calendar-day" + current_month + day_selected}
                      // onClick={() => this.day_mode(day)}
                      >
                  <p className="month-day-number"
                      onClick={()=>_this.day_mode(day)}>{day.number}</p>
                  {_this.render_month_day_events(day)}
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  render_mode_dropdown()
  {
    const _this = this;

    return  <select className="calendar-mode"
                    name="calendar-mode"
                    id="calendar-mode"
                    value={_this.state.mode}
                    onChange={_this.on_mode_change} >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
  }

  render_day(date)
  {
    const _this = this;

    // console.log(date);

    const time_rows = _this.get_time_rows(8, 19, 1);

    return (
      <div className="table-content">
        {
            time_rows.map((time_row, index) =>
            {
              const next_time_row = (index < time_rows.length) ? time_rows[index+1] : null;
              return _this.render_time_row(time_row, next_time_row)
            })
        }
      </div>
    )
  }

  render_day_events(day, time, next_time)
  {
    const _this = this;

    const {mode} = _this.state;

    const week_mode = (mode=="week");
    const day_mode = (mode=="day");

    day.events = _this.get_day_events(day.date);
    if(day.events != null)
    {
      const events = day.events;
      if(events.length > 0)
      {
        const time_components = time.split(':')
        const time_hour = parseInt(time_components[0]);
        const time_minutes = parseInt(time_components[1]);
        var time_row_date = new Date(day.date);
        time_row_date.setHours(time_hour);
        time_row_date.setMinutes(time_minutes);
        time_row_date.setSeconds(0);
        time_row_date.setMilliseconds(0);

        var next_time_row_date;
        if(next_time == null)
        {
          next_time_row_date = new Date(time_row_date);
          next_time_row_date.setHours(time_row_date.getHours());
          next_time_row_date.setMinutes(time_row_date.getMinutes()+30);
        }
        else
        {
          const next_time_components = next_time.split(':')
          const next_time_hour = parseInt(next_time_components[0]);
          const next_time_minutes = parseInt(next_time_components[1]);
          next_time_row_date = new Date(day.date);
          next_time_row_date.setHours(next_time_hour);
          next_time_row_date.setMinutes(next_time_minutes);
        }
        next_time_row_date.setMilliseconds(0);

        return events.map((event, index) =>
              {
                const event_start_hour = event.start_date.getHours();
                const event_end_hour = event.end_date.getHours();

                if( (event.start_date.getDay() == time_row_date.getDay()) &&
                    (event.start_date.getHours() >= time_row_date.getHours()) &&
                    (event.start_date.getMinutes() >= time_row_date.getMinutes()) &&
                    (event.end_date.getHours() <= next_time_row_date.getHours()) &&
                    (event.end_date.getMinutes() <= next_time_row_date.getMinutes())
                ){
                  var event_summary = event.summary;
                  event_summary = event_summary.replaceAll("Lead Lazarus - ", "");

                  var event_description = event.description + " - ";

                  var event_start_minutes =  event.start_date.getMinutes();
                  if(event_start_minutes == "0")
                  {
                    event_start_minutes = "00";
                  }

                  var event_end_minutes =  event.end_date.getMinutes();
                  if(event_end_minutes == "0")
                  {
                    event_end_minutes = "00";
                  }

                  event_description += event.start_date.getHours() + ":" + event_start_minutes + " to ";
                  event_description += event.end_date.getHours() + ":" +event_end_minutes;

                  return (
                    <div key={index}
                         className="event-container" >

                      {week_mode &&<p className="event_summary event_summary_week_mode"
                                      id={event.db_id}
                                      onClick={_this.on_calendar_event_click}>{event_summary}</p>}

                      {day_mode &&<p className="event_summary"
                                      id={event.db_id}
                                      onClick={_this.on_calendar_event_click}>{event_description}</p>}
                    </div>
                  )
                }
              })
      }
    }
  }

  render_week_days_header(days)
  {
    const _this = this;

    const {current_day, todays_date} = this.state;

    return <div className="week-days-header">
            {
              days.map((day, index) =>
              {
                const date = current_day.getDate()+1;
                // const is_today = (day.date.getTime() == todays_date.getTime());
                const is_today = (day.date.getTime() == current_day.getTime());

                const selected_day_style={color:"#ce46d9", fontWeight:700};
                const day_style= is_today ? selected_day_style : {};

                return <div className="week-header-day" style={day_style}>
                              <p className="calendar-day-times-day">{day.day_name}</p>
                              <p className="calendar-day-times-day-name">{day.number}</p>
                            </div>
              })
            }
            </div>
  }

  render_time_row_app1_events(date_time, day)
  {
    const _this = this;

    const time_components = date_time.split(':')
    const time_hour = time_components[0];
    if(time_hour.length == 1)
    {
      time_hour = "0" + time_hour;
    }

    return _this.render_day_events(day, date_time, time_hour + ":30");
  }

  render_time_row_app2_events(date_time, day)
  {
    const _this = this;

    const time_components = date_time.split(':')
    const time_hour = time_components[0];
    if(time_hour.length == 1)
    {
      time_hour = "0" + time_hour;
    }

    const half_hour_start = time_hour + ":30";
    const half_hour_end = (parseInt(time_hour)+1) + ":00";

    return _this.render_day_events(day, half_hour_start, half_hour_end);
  }

  render_time_row_days(time_row, next_time_row)
  {
    const _this = this;

    const days = time_row.days;

    return days.map((day, index) =>
    {
      const next_time = (next_time_row!=null) ? next_time_row.day_time.time : null;

      return <div key={index} className={"time-row-day-times"}>
                   {_this.render_time_row_app1_events(time_row.day_time.time, day)}
                   {_this.render_time_row_app2_events(time_row.day_time.time, day)}
              </div>
    });
  }



  render_time_row(time_row, next_time_row)
  {
    const _this = this;

    const days = time_row.days;

    return (
      <React.Fragment>

        <div className="time-row-container">
          <div className="time-row">
            <div className="time-row-time" >{time_row.day_time.time}</div>
            <div className="time-row-days">
              {_this.render_time_row_days(time_row, next_time_row)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render_week()
  {
    const _this = this;

    const time_rows = _this.get_time_rows(8, 19, 7);

    return (
      <div className="table-content">
        {_this.render_week_days_header(time_rows[0].days)}
        {
            time_rows.map((time_row, index) =>
            {
              return _this.render_time_row(time_row)
            })
        }
      </div>
    )
  }

  render_month()
  {
    const _this = this;

    return (<div className="calendar-body">
              <div className="table-header">
                {
                  this.weekdays.map((weekday, index) => {
                    return <div id={index} data={this.weekdays[index]} key={index} className="weekday">
                            <p>{weekday}</p>
                           </div>
                  })
                }
              </div>

              {this.render_month_days()}

            </div>
          );
  }

  render_day_title(month_label)
  {
    const _this = this;

    const {current_day, locale} = this.state;

    const day_name = current_day.toLocaleDateString(locale, { weekday: 'long' });
    const date = current_day.getDate() + _this.get_date_suffix(current_day.getDate());
    const month = " " + current_day.toLocaleString(locale, { month: 'long' });
    const day_title = day_name + ", " + date + " " + month;

    return <h2 className="calendar-title">
                   {_this.render_today_button()}
                   {_this.render_prev_button()} {_this.render_next_button()}
                   {day_title}

          </h2>
  }

  render_week_title(month_label)
  {
    const _this = this;

    const {current_day, locale} = this.state;

    return <h2 className="calendar-title">
                  {_this.render_today_button()}
                  {_this.render_prev_button()} {_this.render_next_button()}
                  {month_label} { current_day.getFullYear()}
          </h2>
  }

  render_month_title(month_label)
  {
    const _this = this;

    const {current_day, locale} = this.state;

    return <h2 className="calendar-title">
                  {_this.render_today_button()}
                  {_this.render_prev_button()} {_this.render_next_button()}
                  {month_label} { current_day.getFullYear()}
          </h2>
  }

  render_calendar_header()
  {
    const _this = this;

    const {current_day, mode} = this.state;
    const month_label = this.months[current_day.getMonth()] + " ";

    return (
      <div className="calendar-header">
        {(mode=="month") && _this.render_month_title(month_label)}
        {(mode=="week") && _this.render_week_title(month_label)}
        {(mode=="day") && _this.render_day_title(month_label)}
        {_this.render_mode_dropdown()}
      </div>
    );
  }

  render_modal()
  {
    const view = this;

    const {show_modal, is_test, selected_appointment} = view.state;
    if(show_modal)
    {
      return ( <AppointmentModal is_test={is_test} show_modal={true} parent_view={view} appointment={selected_appointment} /> );
    }
  }

  render()
  {
    const _this = this;

    const {current_day, mode} = this.state;

// <h3> Lets go for a <FaBeer />? </h3>

// <div className="prev_next_buttons">
// {_this.render_prev_button()} {_this.render_next_button()}
// </div>

    const month_label = this.months[current_day.getMonth()] + " ";

    return (
      <div className="calendar">
          {_this.render_calendar_header()}
          {(mode=="day") && _this.render_day(current_day)}
          {(mode=="week") && _this.render_week()}
          {(mode=="month") && _this.render_month()}
          {_this.render_modal()}
      </div>
    )
  }
}
export default LLCalendar;
