import React, {Component } from "react";
import Axios from "../utils/Axios";
import MessagesView from "./MessagesView";
import Message from "./Message";
import SendMessage from "./SendMessage";
import ASComponent from './ASComponent';
import Constants from "../Constants";
import base64 from 'base-64';

import $ from "jquery";

class ChatBox extends ASComponent
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      is_live: props.is_live,
      client_name: props.client_name,
      client_lead_id: props.client_lead_id,
      extra_classes: props.extra_classes,
      do_scroll: true,
      conversation_started: false,
      messages: props.messages
    };

    this.on_fetched_messages  = this.on_fetched_messages.bind(this);
    this.on_new_user_message  = this.on_new_user_message.bind(this);
    this.post_new_user_message  = this.post_new_user_message.bind(this);
    this.start_conversation  = this.start_conversation.bind(this);
    this.set_do_scroll  = this.set_do_scroll.bind(this);

    this.messages_view = React.createRef();
  }

  async on_fetched_messages(messages)
  {
    const _this = this;

    _this.send_debug_msg("on_fetched_messages...");

    const queued_user_message = _this.state.queued_user_message;
    if(queued_user_message != null)
    {
      await _this.setState({..._this.state, queued_user_message: null}, async()=>
      {
        await _this.post_new_user_message(queued_user_message);
      });
    }
  }

  async on_new_user_message(user_message)
  {
    const _this = this;

    const {is_live, client_lead_id} = _this.state;

    const messages_view = _this.messages_view.current;
    if(messages_view != null)
    {
      if(is_live && (client_lead_id != null))
      {
        // Add a message to display until the user message is
        // added to the OpenAI messages by the server
        await messages_view.add_fake_message("User", user_message);
        await messages_view.add_fake_message("Assistant", "...", true);

        if(!_this.state.conversation_started)
        {
          _this.send_debug_msg("ChatBox: Starting conversation...");
          await _this.start_conversation(user_message, async()=>
          {
            _this.send_debug_msg("queued_user_message: " + user_message);
            _this.send_debug_msg("ChatBox: Start conversation response");

            // We can't post this user message to OpenAI until the run is complete,
            // so queue it here
            await _this.setState({..._this.state, queued_user_message: user_message});

            // Tell the message list to start fetching messages
            messages_view.start_polling();
          });
        }
        else
        {
          await _this.post_new_user_message(user_message);
        }
      }
    }

      _this.set_do_scroll(true);
  }

  async post_new_user_message(message, on_success)
  {
    const _this = this;

    _this.send_debug_msg("Posting user message: " + message);

    const {conversation_started, client_lead_id} = _this.state;
    if(conversation_started && (client_lead_id != null))
    {
      const response = await Axios.Post('/api/chat/' + client_lead_id, {message}, async(response)=>
      {
        // console.log(response);
        _this.set_do_scroll(true);

        _this.send_debug_msg("ChatBox: Post user mesage response");

        // Kick off polling to get the next assistant message
        await _this.setState({..._this.state, do_scroll: false}, async()=>
        {
          _this.set_do_scroll(true);

          const messages_view = _this.messages_view.current;
          if(messages_view != null)
          {
            messages_view.start_polling();
          }
        });

        if(on_success != null)
        {
          on_success();
        }
      });
    }
  }

  async start_conversation(user_message, on_success)
  {
    const _this = this;

    const {conversation_started, client_lead_id} = _this.state;

    if(!conversation_started && (client_lead_id != null))
    {
      const url = "/api/start_chat_conversation/";
      const user_message_b64 = base64.encode(user_message);

      const post_data=
      {
        lead_id: client_lead_id,
        user_message_b64
      };

      await Axios.Post(url, post_data, async(response2)=>
      {
          // console.log("Conversation start: ", response2);
          _this.setState({..._this.state, conversation_started: true}, ()=>
          {
            _this.set_do_scroll(true);
            if(on_success != null)
            {
                on_success();
            }
          });
      });
    }
  }

  async set_do_scroll(do_scroll)
  {
    const _this = this;
    await _this.setState({do_scroll});
  }

  render()
  {
    const _this = this;

    const {is_live, client_lead_id, read_only, messages} = _this.state;
    if(client_lead_id != null)
    {
       const {messages, do_scroll} = _this.state;
       const chat_padding_top = (_this.props.chat_config != null) ? _this.props.chat_config.chatbox_top_padding : 0;

       var chat_bot_classes = "chat-box ";
       if(_this.state.extra_classes != null)
       {
         chat_bot_classes += _this.state.extra_classes;
       }

       return (
         <React.Fragment>
            <div className={chat_bot_classes} style={{paddingTop: chat_padding_top}}>

              <MessagesView ref={_this.messages_view}
                            messages={messages}
                            is_live={is_live}
                            client_lead_id={client_lead_id}
                            parent_view={_this}
                            do_scroll={do_scroll}/>

                { is_live &&
                  <div className="send-message-container">
                   <SendMessage parent_view={_this}/>
                  </div>
                }
            </div>
        </React.Fragment>
      );
    }
  }
};

export default ChatBox;
