
import React from 'react';
import axios from 'axios';
import { Table, Button, Modal, Pagination } from 'react-bootstrap';
import TableBase from './TableBase';
import ClientModal from '../views/modals/ClientModal.js';
import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import "./css/Table.css";

class ClientTable extends TableBase
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      ...this.state,
      clients: props.clients,
      total_clients: 0,
      show_modal: false,
      is_test: props.is_test
    };

    this.handle_page_change = this.handle_page_change.bind(this);
    this.open_edit_modal = this.open_edit_modal.bind(this);
    this.close_edit_modal = this.close_edit_modal.bind(this);
    this.render_action_buttons = this.render_action_buttons.bind(this);
    this.navigate_to_client_view = this.navigate_to_client_view.bind(this);
  }

  async fetch_data(on_success)
  {
    const view = this;

    const { is_test, pagination } = this.state;
    const token = Cookies.get("token");
    const params = '?page=' + pagination + '&is_test=' + view.props.is_test;
    const url = "/api/clients/";

    await Axios.Get(url, params, async(response)=>
    {
      if(on_success != null)
      {
        on_success(response);
      }

      const array_data = response.data;
      await view.setState({...view.state, data: array_data.data, data_length: array_data.total_data_length});
    });
  }

  handle_page_change(page)
  {
    this.fetch_clients(page);
  }

  navigate_to_client_view(client_label)
  {
    const view = this;

    if(client_label)
    {
      const history = getHistory();
      if(view.state.is_test)
      {
          history.push("/client_demo/" + client_label);
      }
      else
      {
        history.push("/client/" + client_label);
      }
    }
  }

  render_action_buttons(client)
  {
    const view = this;

    return (
      <td>
        <Button className="clients-table-button" variant="warning" onClick={() => this.open_edit_modal(client)}>Edit</Button>
        <Button className="clients-table-button"variant="danger" onClick={() => this.handle_delete(client.label)}>Delete</Button>
      </td>
    );
  }

  render_table_header()
  {
    const view = this;

    return (
      <React.Fragment>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Website</th>
        <th>Is Test</th>
      </React.Fragment>
    );
  }

  render_table_rows(data)
  {
    const view = this;

    return (
      <React.Fragment>
        <tr key={data.id} className="record-table-row" onClick={() => {
          view.navigate_to_client_view(data.label)
        }}>
          <td>{data.name}</td>
          <td>{data.email}</td>
          <td>{data.phone}</td>
          <td>{data.website}</td>
          <td>{data.is_test ? "True" : "False"}</td>
        </tr>
      </React.Fragment>
    );
  }

  render()
  {
    const view =this;

    const { data, show_modal, selected } = this.state;
    if(data != null)
    {
      return (
        <div>
          <Table striped bordered hover responsive>
            <thead>
              {view.render_table_header()}
            </thead>
            <tbody>
              {data && data.map(data => ( view.render_table_rows(data) ))}
            </tbody>
          </Table>

          {view.render_pagination()}

        </div>
      );
    }
    else
    {
      return <div/>;
    }
  }
}

export default ClientTable;
