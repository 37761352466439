import React, {Component} from "react";

class Message extends Component
{
  constructor(props)
  {
    super();

    // This is just to stop REact from whining
    const key = Math.floor(Math.random() * 10000000);

    this.state=
    {
      message: props.message,
      message_index: key,
      placeholder: props.placeholder,
      pips_count: 0
    }
  }

  componentDidMount()
  {
    const _this = this;

    var {pips_count, placeholder} = _this.state;

    if(placeholder)
    {
      _this.interval_id = setInterval(async()=>
      {
        if(++pips_count > 3)
        {
          pips_count = 0;
        }
        await _this.setState({..._this.state, pips_count});
      }, 400);
    }
  }

  async componentWillUnmount()
  {
    const _this = this;
    clearInterval(_this.interval_id);
  }

  render()
  {
    const _this = this;

    const {message, placeholder, pips_count} = _this.state;

    if(message.text == null)
    {
      return;
    }

    var message_index = _this.state.message_index;
    if(message_index == null)
    {
      message_index = Math.floor(Math.random() * 10000);
    }

    const message_text =  message.text.value;
    const bubble_class = message.name === "Assistant" ? "chat-bubble-left" : "chat-bubble-right";
    const username_class = message.name === "Assistant" ? "username-left" : "username-right";
    const paragraph_class = placeholder ? "chat-bubble-placeholder" : "chat-bubble-paragraph";

    const text = message.text.value + "\n";
    var paragraphs = [];
    var paragraph = " ";

    if(placeholder)
    {
      if(pips_count === 0)
      {
          paragraph = " ";
      }
      else
      {
        paragraph = "";
        for(var i=0; i < pips_count; ++i)
        {
            paragraph += ".";
        }
      }
    }
    else
    {
      for(var i=0; i < text.length; ++i)
      {
        paragraph += text[i];
        if((text[i] === "\n") /*|| (text[i] === "\r")*/ /*|| (i==(text.length-1))*/)
        {
          if(i < text.length-1)
          {
            paragraphs.push(paragraph);
            paragraph = "";
          }
        }
      }
    }

    // Handle messaages with no line break character,
    // carriage return or period
    if(paragraphs.length === 0)
    {
        paragraphs.push(paragraph);
    }

    // console.log("message_index: ", message_index);



    return (
      <React.Fragment>
        <div className={bubble_class} id={message_index} data={message_index} key={message_index} label="WTF???">
            <p className={username_class}>{message.name}</p>
            {paragraphs?.map((paragraph, index) => (
              <div key={index}>
                <p className={paragraph_class}>{paragraph}</p>
                <p className="line-break"/>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
};

export default Message;
