import Cookies from 'js-cookie';
import Constants from '../Constants.js';
import axios from 'axios';

 const MAX_REQUESTS_COUNT = 150
 const INTERVAL_MS = 10
 let PENDING_REQUESTS = 0

const lead_lazarus_api_key = "c787ab93-b610-44fd-80b7-d0d2967b9395";

class Axios
{
  static Post(url, data, success_function, error_function, has_form_data)
  {
    const full_url = Constants.SERVER_URL + url;

    const token = Cookies.get('token');
    var headers =  Axios.get_headers(full_url, "POST", token);

    if(has_form_data)
    {
       headers = [];
       headers["Content-Type"] = "multipart/form-data";
    }

    headers["leadlazarus-apikey"] = lead_lazarus_api_key;

    const config = { params:{}, headers:headers };
    axios.post(full_url, data, config)
    .then(function (response)
    {
      if(success_function != null)
      {
        if(response.status === 200)
        {
          const token = response.data.token;
          if(token != null)
          {
            Cookies.set('token', token);
          }

          if(success_function != null)
          {
            success_function(response);
          }
        }
        else
        {
          if(error_function != null)
          {
            error_function(response.data);
          }
        }
      }
    })
    .catch(function (error)
    {
      console.log(error);
      if(error_function != null)
      {
        error_function(error);
      }
    });
  }

  static Put(url, data, success_function, error_function = null)
  {
    const full_url = Constants.SERVER_URL + url;
    // console.log("AXIOS PUT: " + full_url);
    const token = Cookies.get('token');
    // console.log("Token: " + token);

    const config = { params:{}, headers: Axios.get_headers(full_url, "PUT", token) };

    axios.put(full_url, data, config)
    .then(function (response)
    {
      if(success_function != null)
      {
        if(response.status === 200)
        {
          success_function(response);
        }
        else
        {
          if(error_function != null)
          {
            error_function(response.data);
          }
        }
      }
    })
    .catch(function (error)
    {
      console.log(error);
      if(error_function != null)
      {
        error_function(error);
      }
    });
  }

  static UploadFile(data, success_function, error_function = null)
  {
    Axios.Post("/images/", data, success_function, error_function);
  }

  static Login(url, email, password, success_function, error_function = null)
  {
    const full_url = Constants.SERVER_URL + url;
    // console.log("AXIOS GET: " + full_url);
    const token = Cookies.get('token');
    // console.log("Token: " + token);

    const data = {email: email, password: password};
    const config = { params:{}, headers: Axios.get_headers(full_url, "POST", token, data) };

    axios.post(full_url, data, config)
    .then(function (response)
    {
      if(success_function != null)
      {
        if(response.status === 200)
        {
          success_function(response);
        }
        else
        {
          if(error_function != null)
          {
            error_function(response.data);
          }
        }
      }
    })
    .catch(function (error)
    {
      console.log(error);
      if(error_function != null)
      {
        error_function(error);
      }
    });
  }

  static get_headers(url, method, token, data = "")
  {
    const headers =
    {
      "url":url,
      "method":method,
      "Authorization":token,
      "data":data,
      "Access-Control-Allow-Headers": "Accept-Ranges, Accept-Encoding, Origin, X-Requested-With, DNT, Content-Type, Methods, leadlazarus-apikey",
      "Access-Control-Allow-Origin": "*", // Constants.ALLOW_ORIGIN,
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      "crossorigin":"true",
      "Accept-Language":"en-US,en;q=0.9",
      "Accept": "/",
      "Content-Type":"application/json; charset=utf-8",// "Upgrade-Insecure-Requests":"1",
      "Cache-Control": "no-cache",
      "leadlazarus-apikey": lead_lazarus_api_key,
    }
    return headers;
 }

 static GetInternal(url, config, success_function, error_function = null)
 {
    // console.log("Axios.GetInternal url: " + url);

    axios.get(url, config)
    .then(function (response)
    {
      if(success_function != null)
      {
        if(response.status === 200)
        {
          success_function(response);
        }
        else
        {
          if(error_function != null)
          {
            error_function(response.data);
          }
        }
      }
    })
    .catch(function (error)
    {
      console.log(error);
      if(error_function != null)
      {
        error_function(error);
      }
    });
  }

  static Get(url, params, success_function, error_function = null)
  {
    const full_url = Constants.SERVER_URL + url;
    // const full_url = Constants.SERVER_URL + "/api" + url;

    // console.log("AXIOS GET: " + full_url);
    const token = Cookies.get('token');
    // console.log("Token: " + token);

    var final_url = full_url;
    if(params != null)
    {
      final_url += params;
    }

    const config = { params:{}, headers: Axios.get_headers(final_url, "GET", token) }

    Axios.GetInternal(final_url, config, success_function, error_function);
  }

  static GetImage(url, param, success_function, error_function = null)
  {
    const full_url = Constants.SERVER_URL + url;
    const token = Cookies.get('token');
    const final_url = full_url + param;

    const config = { params:{},  headers: Axios.get_headers(final_url, "GET", token) }
    config.headers["Content-Type"] = "image/png";
    // console.log("Content-Type IS: " + config.headers["Content-Type"]);
    // console.log("URL IS: " + final_url);

    // Axios.GetInternal(final_url, config, success_function, error_function);

    axios.get(final_url, config, success_function, error_function)
    .then(function (response)
    {
      if(success_function != null)
      {
        if(response.status === 200)
        {
          success_function(response);
        }
        else
        {
          if(error_function != null)
          {
            error_function(response.data);
          }
        }
      }
    })
    .catch(function (error)
    {
      console.log(error);
      if(error_function != null)
      {
        error_function(error);
      }
    });
  }

  static Delete(url, data, success_function, error_function = null)
  {
    const full_url = Constants.SERVER_URL + "/api" + url;
    // console.log("AXIOS DELETE: " + full_url);
    const token = Cookies.get('token');
    // console.log("Token: " + token);

    const config = { params:{}, headers: Axios.get_headers(full_url, "DELETE", token), data: data };

    axios.delete(full_url, config)
    .then(function (response)
    {
      if(success_function != null)
      {
        if(response.status === 200)
        {
          success_function(response);
        }
        else
        {
          if(error_function != null)
          {
            error_function(response.data);
          }
        }
      }
    })
    .catch(function (error)
    {
      console.log(error);
      if(error_function != null)
      {
        error_function(error);
      }
    });
  }
}


export default Axios;
