import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";
import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';
import BaseView from './BaseView';

import wordmark from '../assets/menu-wordmark.png';

class PasswordResetView extends BaseView
{
    constructor(props)
    {
      super(props);

      var email_link_hash = null;
      const search_params = props.location.search;
      if(search_params != null)
      {
        const search_params_components = search_params.split("=");
        if(search_params_components.length > 1)
        {
          email_link_hash = search_params_components[1];
        }
      }

      this.state =
      {
        password: "",
        confirm_password: "",
        app: props.app,
        email_link_hash,
        did_reset: false
      }

      this.on_send = this.on_send.bind(this);
      this.on_password_change = this.on_password_change.bind(this);
      this.on_confirm_password_change = this.on_confirm_password_change.bind(this);
      this.validate_form = this.validate_form.bind(this);

      this.render_form = this.render_form.bind(this);
      this.render_succeeded = this.render_succeeded.bind(this);
      this.render_failed = this.render_failed.bind(this);
      this.render_result = this.render_result.bind(this);
    }

    async on_send(e)
    {
      e.preventDefault();
      e.stopPropagation();

      var view = this;
      const {password, confirm_password, email_link_hash} = view.state;

      if((password == confirm_password) && (password.length > 3))
      {
        const data = { email_link_hash, password, confirm_password };
        await Axios.Post('/api/password_reset', data, async(response)=>
        {
            const result = (response.status == 200);
            view.setState({...view.state, did_reset: true, result});
            console.log(response);
        },
        (error)=>
        {
            console.log(error);
            view.setState({...view.state, did_reset: true, result: false});
        });
      }
    }

    on_password_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, password: e.target.value});
    }

    on_confirm_password_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, confirm_password: e.target.value});
    }

    validate_form()
    {
        const view = this;

        const {password, confirm_password} = view.state;
        return (password.length > 0) && (password == confirm_password);
    }

    render_form()
    {
      const view = this;
      const app = this.props.app;

      const class_names = "toolbar " + this.props.class_names;

      return (
              <Form onSubmit={view.on_send} className={"login-form"}>
                <FormGroup controlId="password" >
                  <Form.Label>Password</Form.Label>
                  <FormControl
                    value={this.state.password}
                    onChange={this.on_password_change}
                    type="password"
                  />
                </FormGroup>
                <br/>
                <FormGroup controlId="confirm_password" >
                  <Form.Label>Confirm Password</Form.Label>
                  <FormControl
                    value={this.state.confirm_password}
                    onChange={this.on_confirm_password_change}
                    type="password"
                  />
                </FormGroup>
                <Button block disabled={!this.validate_form()} type="submit" className="as_button">
                  Reset password
                </Button>
              </Form>
      );
    }

    render_succeeded()
    {
      const view = this;
      const app = this.props.app;

      const {result} = view.state;

        return (

          <div>
            <h3 className="ll-h3">Password reset succeeded!</h3>
            <p>Please login when you are ready</p>
            <Button block type="submit" className="as_button password_reset_button" onClick={()=>{window.location.href="/"}}>
              Ok
            </Button>
          </div>
        );
    }

    render_failed()
    {
      const view = this;
      const app = this.props.app;

      const {result} = view.state;

        return (

          <div>
            <h3 className="ll-h3">Password reset failed!</h3>
            <p>Please contact Lead Lazarus</p>
            <Button block type="submit" className="as_button password_reset_button" onClick={()=>{window.location.href="/"}}>
              Ok
            </Button>
          </div>
        );
    }

    render_result()
    {
      const view = this;
      const app = this.props.app;

      const {result} = view.state;

      if(result)
      {
        return view.render_succeeded();
      }
      return view.render_failed();
    }

    render()
    {
      const view = this;
      const app = this.props.app;

      const {did_reset} = view.state;

      return (
          <Container className="absolute-center is-responsive login-container" style={{marginTop: "20%"}}>
            <Row>
              <Col md={3}></Col>
              <Col lg={6} md={6} xs={12} sm={12}  className="login-center-column">
              <img src={wordmark} className="signin-logo"/>

              {(!did_reset) && view.render_form()}
              {did_reset && view.render_result()}

              </Col>
              <Col md={3}></Col>
            </Row>
          </Container>
      );

      /*
      if(!did_reset)
      {
          return view.render_form();
      }
      else
      {

      }*/
    }



}

export default PasswordResetView;
