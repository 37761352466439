import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";
import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';
import BaseView from './BaseView';

import wordmark from '../assets/menu-wordmark.png';

class PasswordResetRequestView extends BaseView
{
    constructor(props)
    {
      super(props);

      this.state = {
        email: "",
        app: props.app
      }

      this.on_password_reset_request = this.on_password_reset_request.bind(this);
      this.on_email_change = this.on_email_change.bind(this);
      this.validate_form = this.validate_form.bind(this);

      this.render_form = this.render_form.bind(this);
      this.render_succeeded = this.render_succeeded.bind(this);
      this.render_failed = this.render_failed.bind(this);
      this.render_result = this.render_result.bind(this);
    }

    on_password_reset_request(e)
    {
      e.preventDefault();
      e.stopPropagation();

      var view = this;

      // TODO: Email validation here

      const data =
      {
        email: this.state.email,
      }

      Axios.Post('/api/password_reset_request/', data, async(response)=>
      {
          const result = (response.status == 200);
          await view.setState({...view.state, did_request: true, result});
      },
      (error)=>
      {
          console.log(error);
          view.setState({...view.state, did_request: true, result: false});
      });
    }

    on_email_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, email: e.target.value});
    }

    validate_form()
    {
        return (this.state.email.length > 0);
    }

    render_succeeded()
    {
      const view = this;
      const app = this.props.app;

      const {result} = view.state;

        return (

          <div>
            <h3 className="ll-h3">Password reset request succeeded!</h3>
            <p>You have been sent an email to reset your password</p>
            <Button block type="submit" className="as_button password_reset_button" onClick={()=>{window.location.href="/"}}>
              Ok
            </Button>
          </div>
        );
    }

    render_failed()
    {
      const view = this;
      const app = this.props.app;

      const {result} = view.state;

        return (

          <div>
            <h3 className="ll-h3">Password reset request failed!</h3>
            <Button block type="submit" className="as_button password_reset_button" onClick={()=>{window.location.href="/"}}>
              Ok
            </Button>
          </div>
        );
    }

    render_form()
    {
      const app = this.props.app;

      const view = this;
      const class_names = "toolbar " + this.props.class_names;

      return (

              <Form onSubmit={view.on_password_reset_request} className={"login-form"}>
                <FormGroup controlId="email" >
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    autoFocus
                    type="email"
                    value={this.state.email}
                    onChange={this.on_email_change}
                  />
                </FormGroup>
                <Button block disabled={!this.validate_form()} type="submit" className="as_button">
                  Request password reset
                </Button>
              </Form>
      );
    }

    render_result()
    {
      const view = this;
      const app = this.props.app;

      const {result} = view.state;

      if(result)
      {
        return view.render_succeeded();
      }
      return view.render_failed();
    }

    render()
    {
        const app = this.props.app;

        const view = this;
        const {did_request} = view.state;

        const class_names = "toolbar " + this.props.class_names;

        return (
            <Container className="absolute-center is-responsive login-container" style={{marginTop: "20%"}}>
              <Row>
                <Col md={3}></Col>
                <Col lg={6} md={6} xs={12} sm={12}  className="login-center-column">
                <img src={wordmark} className="signin-logo"/>
                  {(!did_request) && view.render_form()}
                  {did_request && view.render_result()}
                </Col>
                <Col md={3}></Col>
              </Row>
            </Container>
        );
    }

}

export default PasswordResetRequestView;
