
import React, { Component } from 'react';
import { Button, Form, Container, ToggleButton, ToggleButtonGroup, Row, Col } from 'react-bootstrap';
import ASComponent from './ASComponent';
import Checkbox from './Checkbox';
import Axios from '../utils/Axios';
import Papa from 'papaparse';
import base64 from 'base-64';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import play from '../assets/play.png';
import pause from '../assets/pause.png';

class LeadReactivation extends ASComponent
{
    constructor(props)
    {
        super(props);

        this.state =
        {
          parent_view: props.parent_view,
          client_label:  props.client_label,
          reactivation_status: "Inactive",
          lead_list_status: "Please upload a csv file of leads",
          assistant_ready: "Assistant not ready",
          is_test: props.is_test,
        };

        // this.continue_button_enabled = this.continue_button_enabled.bind(this);
        // this.check_config = this.check_config.bind(this);

        this.navigate_to_leads_view = this.navigate_to_leads_view.bind(this);
        this.get_client_job = this.get_client_job.bind(this);
        this.check_config = this.check_config.bind(this);
        this.on_processing_click = this.on_processing_click.bind(this);
    }

    async componentDidMount()
    {
        super.componentDidMount();

        const view = this;
        await view.get_client_by_label(view.state.client_label, async()=>
        {
          view.get_client_job();
          const intervalId = setInterval(async () =>
          {
            await view.check_config();
            await view.get_client_job();
          }, 1000);
        });
    }

    async componentWillUnmount()
    {
      const _this = this;
      clearInterval(_this.intervalId);
    }

    navigate_to_leads_view(client_label)
    {
      const view = this;

      if(view.state.client_label != null)
      {
        const history = getHistory();
        history.push("/leads/" + view.state.client_label)
      }
    }

    async get_client_job()
    {
      const view = this;

      if(view.state.client_label != null)
      {
        await Axios.Get('/api/client_job_by_client/', view.state.client_label, async(response)=>
        {
          const client_job = response.data;
          if(client_job != null)
          {
            view.setState({client_job: response.data, clear_jobs: false, reactivation_status: client_job.status});
          }
        });
      }
    }

    continue_button_enabled()
    {
      const view = this;

      const parent_view = view.state.parent_view;
      if(parent_view != null)
      {
        return parent_view.has_leads() && parent_view.assistant_is_ready();
      }
      return false;
    }

    async check_config()
    {
      const view = this;

      const parent_view = view.props.parent_view;

      if(parent_view != null)
      {
        if(parent_view.has_leads())
        {
            await view.setState({...view.state, lead_list_status: "Leads are available"}, ()=>
            {
                //  REACT IS NOT SETTING THE FUCKING STATE CORRECTLY, SO I'LL HAVE TO FUCKING FORCE IT
                view.state.lead_list_status = "Leads are available";
                console.log("lead_list_status: ", view.state.lead_list_status);
            });
        }

        if(parent_view.assistant_is_ready())
        {
            view.setState({...view.state, assistant_ready: "Assistant is ready"}, ()=>
            {
                view.state.assistant_ready = "Assistant is ready";
            });
        }
      }
    }


    on_processing_click()
    {
      const view = this;

      const { client, client_job, is_test } = view.state;

      if(client != null)
      {
        var processing_enabled = true;
        if(client_job != null)
        {
          if(client_job.status != "Suspended")
          {
              processing_enabled = (client_job.status == "Processing") ? false : true;
          }
        }

        const job_data=
        {
          processing_enabled: processing_enabled,
          client_id: client.id,
          is_test: is_test,
          clear_jobs: view.state.clear_jobs,
          status: "Processing"
        };

        console.log("processing_enabled: ", processing_enabled);
        Axios.Post('/api/client_processing', job_data, async(response)=>
        {
            console.log("client_processing response: ", response);
            const client_job = response.data;
            await view.setState({client_job, client_job_id: client_job.id});
            // await view.get_client(view.state.client_label);
        });
      }
    }

    render()
    {
      const view = this;

      const {is_test, reactivation_status, lead_list_status, assistant_ready, client,  client_job} = view.state;

      var processing_enabled = true;
      var processing_img = play;
      if(client_job != null)
      {
        processing_enabled = (is_test || (client_job.status != "Processing Finished") && (client_job.status != "Suspended"));
        processing_img = (client_job.status == "Processing") ? pause : play;
      }

      if(client != null)
      {
        const title = is_test ? "Lead Reactivation Test" : "Lead Reactivation";

        return (
            <Container className="demo-block">
                <h1>{title}</h1>
                {
                  client && <Row>
                              <Col>
                                <h3>Client</h3>
                                <p style={{marginLeft: "20px"}}>{client.name}</p>
                              </Col>
                              <Col>
                                <h3>Reactivation Status</h3>
                                <p style={{marginLeft: "20px"}} >{reactivation_status}</p>
                              </Col>
                              <Col>
                                {processing_enabled && processing_img && <img src={processing_img} className="client-processing-image" onClick={view.on_processing_click}/>}
                              </Col>
                            </Row>
                }

                <Row>
                  <Col>
                    <h3>Leads</h3>
                    <div style={{marginLeft: "15px"}}>{lead_list_status}</div>
                  </Col>
                  <Col>
                    <h3>Assistant</h3>
                    <div style={{marginLeft: "15px"}}>{assistant_ready}</div>
                  </Col>
                </Row>

                <Row>
                  <Button style={{marginLeft: "30px"}} variant="primary" onClick={view.navigate_to_leads_view}>
                      Leads
                  </Button>
                </Row>
            </Container>
        );
      }
    }
}

export default LeadReactivation;
