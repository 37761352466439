import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";
import BaseView from './BaseView';
import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';

class UserPermissionsView extends BaseView
{
  constructor(props)
  {
    super(props);

    var id = props.user_id;
    if( props.match != null)
    {
      if( props.match.params != null)
      {
        if( props.match.params.id != null)
        {
          id = props.match.params.id;
        }
      }
    }

    this.state =
    {
      ...this.state,
      id: id,
      user: null,
      user_group: null,
      user_group_changed: false,
      user_permissions_changed: false,
      enable_render: props.enable_render,
      parent_view: props.parent_view
    }

    this.get_user = this.get_user.bind(this);
    this.get_logged_in_user = this.get_logged_in_user.bind(this);
    this.get_loggedin_user_group = this.get_loggedin_user_group.bind(this);
    this.loggedin_user_is_admin = this.loggedin_user_is_admin.bind(this);
    this.loggedin_user_is_client_admin = this.loggedin_user_is_client_admin.bind(this);
    this.loggedin_user_is_referrer = this.loggedin_user_is_referrer.bind(this);
    this.loggedin_user_is_user = this.loggedin_user_is_user.bind(this);

    this.get_user_groups = this.get_user_groups.bind(this);
    this.get_user_group_permissions = this.get_user_group_permissions.bind(this);
    this.get_user_permissions = this.get_user_permissions.bind(this);
    this.get_active_user_permissions = this.get_active_user_permissions.bind(this);
    this.get_effective_user_permissions = this.get_effective_user_permissions.bind(this);
    this.has_permission = this.has_permission.bind(this);
    this.loggedin_user_has_permission = this.loggedin_user_has_permission.bind(this);
    this.handle_user_group_radio_change = this.handle_user_group_radio_change.bind(this);
    this.handle_user_permission_change = this.handle_user_permission_change.bind(this);
    this.get_organization = this.get_organization.bind(this);
    this.set_effective_user_permission = this.set_effective_user_permission.bind(this);
    this.on_apply = this.on_apply.bind(this);

    this.render_checkbox = this.render_checkbox.bind(this);
    this.render_user_permissions_editor = this.render_user_permissions_editor.bind(this);
  }

  async componentDidMount(on_success)
  {
    const view = this;

    view.state.user = null;
    view.state.user_group = null;

    await view.get_logged_in_user(async(token, loggedin_user)=>
    {
      if(view.state.id != null)
      {
        await view.get_permissions(view.state.id, on_success);
      }
      else
      if(loggedin_user != null)
      {
        await view.setState({id: loggedin_user.id})
        {
          await view.get_permissions(loggedin_user.id, on_success);
        }
      }

    });
  }

  async get_permissions(id, on_success)
  {
    const view = this;

    await view.get_user(id, async(user)=>
    {
      if(user != null)
      {
        await view.get_user_groups(async()=>
        {
            await view.get_loggedin_user_group(async()=>
            {
              await view.get_user_group_permissions(async()=>
              {
                  await view.get_user_permissions(async()=>
                  {
                      await view.get_active_user_permissions(id, async()=>
                      {
                        await view.get_effective_user_permissions(id, async()=>
                        {
                          if(view.loggedin_user_is_admin())
                          {
                            await view.setState({user_is_admin: true}, async()=>
                            {
                              if(view.app != null)
                              {
                                await view.app.setState({user_is_admin: true});
                              }
                            });
                          }
                          else
                          if(view.loggedin_user_is_client_admin())
                          {
                            await view.setState({user_is_client_admin: true}, async()=>
                            {
                              if(view.app != null)
                              {
                                await view.app.setState({user_is_client_admin: true});
                              }
                            });
                          }
                          else
                          if(view.loggedin_user_is_referrer())
                          {
                            await view.setState({user_is_referrer: true}, async()=>
                            {
                              if(view.app != null)
                              {
                                await view.app.setState({user_is_referrer: true});
                              }
                            });
                          }

                          if(on_success != null)
                          {
                            on_success();
                          }
                        });
                      });
                  });
              });
            });
        });
      }
    });
  }

  async get_logged_in_user(success_callback, error_callback, no_token_callback)
  {
    var view = this;

    const token = Cookies.get('token');
    if(token != null)
    {
      if(token != "null")
      {
        await Axios.Get('/api/user_logged_in', token,
        (response)=>
        {
             const loggedin_user= response.data.user;
             view.setState({...view.state, loggedin_user}, ()=>
             {
               // console.log("loggedin_user: ", loggedin_user);
              if(success_callback !== undefined)
              {
                success_callback(token, loggedin_user);
              }
            });
        },
        (error)=>
        {
            console.log(error);
        });
      }
      else
      if(no_token_callback != null)
      {
        no_token_callback()
      }
    }
    else
    if(no_token_callback != null)
    {
      no_token_callback()
    }
  }

  async get_loggedin_user_group(on_success)
  {
    const view = this;

    if(view.state.loggedin_user != null)
    {
      const user_id = view.state.id;
      const usergroupid = view.state.loggedin_user.usergroupid;
      const url = '/api/user_group/';
      await Axios.Get(url, usergroupid, async(response)=>
      {
          if(response.data != null)
          {
            const loggedin_user_group = response.data;
            await view.setState({...view.state, loggedin_user_group}, async()=>
            {
              // console.log("loggedin_user_group: ", loggedin_user_group);
              if(on_success != null)
              {
                on_success(view.state.loggedin_user_group);
              }
            });
          }
      });
    }
  }

  async get_user(id, on_success)
  {
    var view = this;

    await Axios.Get('/api/user/' + id, null, async(response)=>
    {
        const user = response.data;
        if(user != null)
        {
          view.setState({...view.state, user}, async()=>
          {
            on_success(view.state.user);
         });
        }
    });
  }

  loggedin_user_is_admin()
  {
    const view = this;

    const {loggedin_user, loggedin_user_group} = view.state;
    if((loggedin_user != null) && (loggedin_user_group != null))
    {
      return loggedin_user_group.name === "Admin";
    }
  }

  loggedin_user_is_client_admin()
  {
    const view = this;

    const {loggedin_user, loggedin_user_group} = view.state;
    if((loggedin_user != null) && (loggedin_user_group != null))
    {
      return loggedin_user_group.name === "ClientAdmin";
    }
  }

  loggedin_user_is_referrer()
  {
    const view = this;

    const {loggedin_user, loggedin_user_group} = view.state;
    if((loggedin_user != null) && (loggedin_user_group != null))
    {
      return loggedin_user_group.name === "Referrer";
    }
  }

  loggedin_user_is_user()
  {
    const view = this;

    const {loggedin_user, loggedin_user_group} = view.state;
    if((loggedin_user != null) && (loggedin_user_group != null))
    {
      return loggedin_user_group.name === "User";
    }
  }

  logged_in_admin()
  {
    const view = this;

    const loggedin_user = view.state.loggedin_user;
    if(loggedin_user != null)
    {
      const user_is_admin = view.loggedin_user_is_admin();
      if(user_is_admin)
      {
        return true;
      }
    }
    return false;
  }

  async get_user_groups(on_success)
  {
    const view = this;

    const user_id = view.state.id;
    await Axios.Get('/api/user_groups/', null, async(response)=>
    {
        if(response.data != null)
        {
          var user_groups = response.data;

          // Sort user_groups by rank
          user_groups.sort(function(a, b){return a.rank - b.rank});

          await view.setState({...view.state, user_groups}, async()=>
          {
            const user_group = view.find_user_group(view.state.user.usergroupid);
            await view.setState({...view.state, user_group}, async()=>
            {
              if(on_success != null)
              {
                on_success();
              }
            });
          });
        }
    });
  }

  async get_user_group_permissions(on_success)
  {
    const view = this;

    const user_id = view.state.id;
    await Axios.Get('/api/user_group_permissions/', null, async(response)=>
    {
        if(response.data != null)
        {
          const user_group_permissions = response.data;
          view.setState({...view.state, user_group_permissions}, ()=>{
            on_success();
          });
        }
    });
  }

  async get_user_permissions(on_success)
  {
    const view = this;

    const user_id = view.state.id;
    await Axios.Get('/api/user_permissions/', null, async(response)=>
    {
        if(response.data != null)
        {
          const user_permissions = response.data;
          view.setState({...view.state, user_permissions}, async()=>{
              on_success();
          });
        }
    });
  }

  async get_active_user_permissions(user_id, on_success)
  {
    const view = this;

    if(view.state.user != null)
    {
      // const user_id = view.state.user.id;
      await Axios.Get('/api/active_user_permissions/'+user_id, null, async(response)=>
      {
          const active_user_permissions = response.data;
          if(active_user_permissions != null)
          {
            await view.setState({...view.state, active_user_permissions}, ()=>{
              on_success();
            });
          }
      });
    }
  }

  async get_effective_user_permissions(user_id, on_success)
  {
    const view = this;
    if(view.state.user_permissions == null)
    {
      // return;
    }

    const active_user_permissions = view.state.active_user_permissions;

    // Create user permission arrays for each user group
    var user_permissions_by_group = [];
    const user_groups = view.state.user_groups;
    for(var i=0; i < user_groups.length; ++i)
    {
      const ug_id = user_groups[i].id;
      user_permissions_by_group[ug_id] = [];
    }

    // Add the user permissions to the arrays
    var user_permissions = view.state.user_permissions;
    /*
    if(user_permissions == null)
    {
      await view.get_user_permissions(()=>
      {
        user_permissions = view.state.user_permissions
      });
    }
    */

    // console.log("user_permissions: ", user_permissions);

    for(var i=0; i < user_permissions.length; ++i)
    {
      var up = user_permissions[i];
      up.active = true;
      const group_user_permission =
      {
        usergroupid: up.usergroupid,
        userpermissionid: up.id,
        id: up.id,
        name: up.name
      };

      user_permissions_by_group[up.usergroupid].push(group_user_permission);
    }

    //  console.log("user_permissions_by_group: ", user_permissions_by_group);

    await view.setState({user_permissions_by_group});

    var effective_user_permissions = [];

    // First, add all allowed permissions for each user group below
    // the current user group, checking each one to be sure that it has not
    // been disallowed by an active user permission override
    const logged_in_user_group_rank = view.state.user_group.rank;
    for(var i=logged_in_user_group_rank-1; i < user_groups.length; ++i)
    {
      const user_group_id = user_groups[i].id;

      const group_user_permissions = user_permissions_by_group[user_group_id];
      for(var j=0; j < group_user_permissions.length; ++j)
      {
        const group_user_permission = group_user_permissions[j];
        const active_user_permission = view.find_active_user_permission(group_user_permission.id);
        if(active_user_permission != null)
        {
          // Is this user permission allowed?
          // if(!active_user_permission.active)
          {
            continue;
          }
        }
        else
        {
          // No active user permission is overriding the user
          // group permission, so just add it
          const effective_user_permission =
          {
            usergroupid: group_user_permission.usergroupid,
            userpermissionid: group_user_permission.userpermissionid,
            userid: view.state.user.id,
            id: group_user_permission.userpermissionid,
            active: true
          };

          effective_user_permissions.push(effective_user_permission);
        }
      }
    }

    // Add other user permissions from other user groups,
    // if they have been allowed
    for(var i=0; i < active_user_permissions.length; ++i)
    {
      const active_user_permission = active_user_permissions[i];
      if(active_user_permission.active)
      {
        const user_permission = view.find_user_permission(active_user_permission.userpermissionid);
        if(user_permission != null)
        {
          if(!view.find_effective_user_permission(user_permission.id))
          {
            // Create active user permission from user permission
            const effective_user_permission =
            {
              usergroupid: user_permission.usergroupid,
              userpermissionid: user_permission.id,
              id: user_permission.id,
              userid: view.state.user.id,
              active: true,
              name: user_permission.name
            };

            effective_user_permissions.push(effective_user_permission);
          }
        }
      }
    }

    // if(view.state.loggedin_user.id == user_id)
    {
      view.setState({...view.state, effective_user_permissions}, ()=>
      {
        if(on_success != null)
        {
          on_success(effective_user_permissions);
        }
      });
    }
    /*
    else
    {
      if(on_success != null)
      {
        on_success(effective_user_permissions);
      }
    }
    */

    // console.log("effective_user_permissions: ", effective_user_permissions);

    return effective_user_permissions;
  }

  find_active_user_permission(userpermissionid)
  {
    const view = this;

    const active_user_permissions = view.state.active_user_permissions;
    for(let i=0; i < active_user_permissions.length; ++i)
    {
      if(active_user_permissions[i].userpermissionid == userpermissionid)
      {
        return active_user_permissions[i];
      }
    }
    return null;
  }

  find_active_user_permission_by_name(name)
  {
    const view = this;

    const active_user_permissions = view.state.active_user_permissions;
    for(let i=0; i < active_user_permissions.length; ++i)
    {
      if(active_user_permissions[i].name == name)
      {
        return active_user_permissions[i];
      }
    }
    return null;
  }

  find_user_group(user_group_id)
  {
    const view = this;

    const user_groups = view.state.user_groups;
    if(user_groups   != null)
    {
      for(let i=0; i < user_groups.length; ++i)
      {
        if(user_groups[i].id === user_group_id)
        {
          return user_groups[i];
        }
      }
    }
    return null;
  }

  find_user_group_by_name(user_group_name)
  {
    const view = this;

    const user_groups = view.state.user_groups;
    if(user_groups   != null)
    {
      for(let i=0; i < user_groups.length; ++i)
      {
        if(user_groups[i].name === user_group_name)
        {
          return user_groups[i];
        }
      }
    }
    return null;
  }

  find_user_group_permission(userpermissionid)
  {
    const view = this;

    const user_permissions_by_group = view.state.user_permissions_by_group;
    if(user_permissions_by_group   != null)
    {
      for(let i=0; i < user_permissions_by_group.length; ++i)
      {
        const user_group_permissions = user_permissions_by_group[i];
        for(let j=0; j < user_group_permissions.length; ++j)
        {
          if(user_group_permissions[j].id === userpermissionid)
          {
            return user_group_permissions[j];
          }
        }
      }
    }
    return null;
  }

  user_has_user_group_permission(userpermissionid)
  {
    const view = this;

    const {user_permissions_by_group, user_group} = view.state;
    if((user_permissions_by_group != null) && (user_group != null))
    {
      const user_group_id = view.state.user_group_id;
      const user_group_permissions = user_permissions_by_group[user_group.id];
      for(let i=0; i < user_group_permissions.length; ++i)
      {
        if(user_group_permissions[i].id == userpermissionid)
        {
          return user_group_permissions[i];
        }
      }
    }
    return null;
  }

  find_user_permission(userpermissionid)
  {
    const view = this;

    const user_permissions = view.state.user_permissions;
    if(user_permissions   != null)
    {
      for(let i=0; i < user_permissions.length; ++i)
      {
        if(user_permissions[i].id === userpermissionid)
        {
          return user_permissions[i];
        }
      }
    }
    return null;
  }

  find_effective_user_permission(userpermissionid)
  {
    const view = this;

    const effective_user_permissions = view.state.effective_user_permissions;
    if(effective_user_permissions   != null)
    {
      for(let i=0; i < effective_user_permissions.length; ++i)
      {
        const effective_user_permission = effective_user_permissions[i];
        if(effective_user_permission.userpermissionid === userpermissionid)
        {
          return effective_user_permission;
        }
      }
    }
    return null;
  }

  find_user_permissions_by_user_group_name(user_group_name)
  {
      const view = this;

      const user_group = view.find_user_group_by_name(user_group_name);
      if(user_group != null)
      {
        const user_permissions_by_group = view.state.user_permissions_by_group;
        if(user_permissions_by_group != null)
        {
          const user_group_permissions = user_permissions_by_group[user_group.id];
          return user_group_permissions;
        }
      }
      return null;
  }

  has_permission(name)
  {
    const view = this;

    var userpermissionid = null;
    const user_permissions = view.state.user_permissions;
    if(user_permissions != null)
    {
      for(let i=0; i < user_permissions.length; ++i)
      {
        const user_permission = user_permissions[i];
        if(user_permission.name === name)
        {
          userpermissionid = user_permission.id;
          break;
        }
      }

      if(userpermissionid != null)
      {
        const effective_user_permissions = view.state.effective_user_permissions;
        if(effective_user_permissions != null)
        {
          for(let i=0; i < effective_user_permissions.length; ++i)
          {
            const effective_user_permission = effective_user_permissions[i];
            if(effective_user_permission.id === userpermissionid)
            {
              return effective_user_permission.active;
            }
          }
        }
      }
    }
    return false;
  }

  // This us using parent_view
  loggedin_user_has_permission(name)
  {
    const view = this;

    var parent_view = view.state.parent_view;
    if(parent_view == null)
    {
      parent_view = view;
    }

    if(parent_view != null)
    {
      var userpermissionid = null;
      const user_permissions = parent_view.state.user_permissions;
      if(user_permissions != null)
      {
        for(let i=0; i < user_permissions.length; ++i)
        {
          const user_permission = user_permissions[i];
          if(user_permission.name === name)
          {
            userpermissionid = user_permission.id;
            break;
          }
        }

        if(userpermissionid != null)
        {
          const effective_user_permissions = parent_view.state.effective_user_permissions;
          if(effective_user_permissions != null)
          {
            for(let i=0; i < effective_user_permissions.length; ++i)
            {
              const effective_user_permission = effective_user_permissions[i];
              if(effective_user_permission.id === userpermissionid)
              {
                return effective_user_permission.active;
              }
            }
          }
        }
      }
    }
    return false;
  }

  get_user_group_name(usergroupid, user_groups)
  {
    for(let user_group of user_groups)
    {
      if(usergroupid == user_group.id)
      {
        return user_group.name;
      }
    }
    return null
  }

  user_is_admin(user)
  {
    if(user != null)
    {
      return (user.user_group_name == "Admin");
    }
    return false;
  }

  async get_organization(organization_id, on_success)
  {
    var view = this;

    await Axios.Get('/api/organization/' + organization_id, null, (response)=>
    {
        if(response.data != null)
        {
            const organization = response.data;
            if(organization != null)
            {
               view.setState({...view.state, organization}, async()=>
               {
                 if(on_success != null)
                 {
                  on_success(view.state.organization);
                 }
              });
            }
        }
    });
  }

  async set_effective_user_permission(userpermissionid, active)
  {
    const view = this;

    if(view.loggedin_user_has_permission("Edit User"))
    {
      var found = false;
      var effective_user_permissions = view.state.effective_user_permissions;
      if(effective_user_permissions   != null)
      {
        const found = view.find_effective_user_permission(userpermissionid);
        if(found)
        {
          found.active = active;
        }

        // If this user permission override does not already exist,
        // and the user does not have it as a
        // user group permission, we need to add it as a user permission
        // override
        const user_permission = view.find_user_permission(userpermissionid);
        const user_group_permission = view.user_has_user_group_permission(userpermissionid);
        if((found == null) && (user_permission!=null) /*&& (user_group_permission==null)*/)
        {
          const effective_user_permission=
          {
              active: true,
              usergroupid: user_permission.usergroupid,
              userpermissionid: userpermissionid,
              id: userpermissionid,
              userid: view.state.user.id,
              active: active,
              name: user_permission.name,
          };
          effective_user_permissions.push(effective_user_permission);
        }

        await view.setState({...view.state, effective_user_permissions});
      }
    }
    return null;
  }

  effective_user_permission_checked(userpermissionid)
  {
    const view = this;

    const effective_user_permissions = view.state.effective_user_permissions;
    if(effective_user_permissions != null)
    {
      for(let i=0; i < effective_user_permissions.length; ++i)
      {
        const effective_user_permission = effective_user_permissions[i];
        if(effective_user_permission.id === userpermissionid)
        {
          return effective_user_permission.active;
        }
      }
    }
    return false;
  }

  handle_user_group_radio_change(e)
  {
    const view = this;

    if(view.loggedin_user_has_permission("Edit User"))
    {
      const user_group_name = e.target.name;
      const user_group = view.find_user_group_by_name(user_group_name);
      if(user_group != null)
      {
        view.setState({...view.state, user_group, user_group_changed:true, active_user_permissions: []}, async()=>
        {
          const parent_user_permissions = view.state.parent_view.state.user_permissions;
          if((parent_user_permissions != null) && (view.state.user_permissions == null))
          {
            view.setState({user_permissions:parent_user_permissions}, async()=>
            {
              await view.get_effective_user_permissions();
            });
          }
          else
          {
            await view.get_effective_user_permissions();
          }
        });
      }
    }

  }

  handle_user_permission_change(e)
  {
    const view = this;

    if(view.loggedin_user_has_permission("Edit User"))
    {
      const user_permission_name = e.target.name;
      const is_checked = e.target.checked;
      const userpermissionid = e.target.id;

      // If we want this permission and it is not set by default as
      // a user gorup permission, we need to set it as a
      // user permission override
      view.setState({...view.state, user_permissions_changed: true}, ()=>
      {
          view.set_effective_user_permission(userpermissionid, is_checked);
      });
    }
  }

  async on_apply()
  {
    const view = this;

    if(view.loggedin_user_has_permission("Edit User"))
    {
      const {user_group, user_group_changed, user_permissions_changed, id, active_user_permissions} = view.state;
      if(user_group_changed && (user_group != null))
      {
        view.get_effective_user_permissions(async (effective_user_permissions)=>{
          await Axios.Put('/api/set_user_group/'+id, {usergroupid: user_group.id, active_user_permissions}, function(response)
          {
            view.setState({...view.state, user_group_changed: false});
          });
        });
      }

      if(user_permissions_changed)
      {
        const effective_user_permissions = view.state.effective_user_permissions;
        await Axios.Put('/api/set_active_user_permissions/'+id,
          {active_user_permissions:effective_user_permissions}, (response)=>
        {
          view.setState({...view.state, user_permissions_changed: false});
        });
      }
    }
  }

  render_user_permissions_editor()
  {
    const view = this;

    const {user, user_group, user_permissions} = view.state;
    if((user != null) && (user_group != null))
    {
        return (
          <React.Fragment>
            <div className="user_data_container">
              <Row>
                <h2> User Permissions </h2>
              </Row>
              <Row>
                <Col md={2}>
                  <h5 className="user-group-editor-h5">Set User Group</h5>
                </Col>
              </Row>
              <Row className="user-group-radio-row">
                {view.render_user_group_radio("id-user", "User", "User")}
                {view.render_user_group_radio("id-referrer", "Referrer", "Referrer")}
                {view.render_user_group_radio("id-admin", "Admin", "Admin")}
              </Row>
              <Row>
                <Col md={2}>
                  <h5 className="user-group-editor-h5">User Permissions</h5>
                </Col>
              </Row>
              <Row>
                {view.render_permissions_rows("User")}
              </Row>
              <Row>
                {view.render_permissions_rows("Referrer")}
              </Row>
              <Row>
                {view.render_permissions_rows("Admin")}
              </Row>
              <br/>
              <Row>
                <ASButton color="dark" on_click={view.on_apply} label={view.state.filter_title} id="table_delete_button" label="Apply"/>
              </Row>
            </div>
          </React.Fragment>
        );
      }
  }

  render_permissions_rows(user_group_name)
  {
    const view = this;

    /*
    const parent_user_permissions = view.state.parent_view.state.user_permissions;
    if((parent_user_permissions != null) && (view.state.user_permissions == null))
    {
      view.setState({user_permissions:parent_user_permissions}, async()=>
      {
        await view.get_effective_user_permissions();
      });
    }
    */

    const user_permissions = view.find_user_permissions_by_user_group_name(user_group_name);
    if(user_permissions != null)
    {
      return (
        <React.Fragment>
            <Col md={12}>
              {view.render_permissions_row(user_permissions, 0)}
              {view.render_permissions_row(user_permissions, 4)}
            </Col>
        </React.Fragment>
      );
    }
  }

  render_permissions_row(user_permissions, start)
  {
    const view = this;

    const eup0 = user_permissions[start];
    if((eup0 != null) && (eup0.id != null))
    {
      const eup1 = user_permissions[start+1];
      const eup2 = user_permissions[start+2];
      const eup3 = user_permissions[start+3];

      return(
        <Row>
          {(start < user_permissions.length) && view.render_checkbox(eup0.id, eup0.name, eup0.name, view.effective_user_permission_checked(eup0.id) )}
          {(start+1 < user_permissions.length) && view.render_checkbox(eup1.id, eup1.name, eup1.name, view.effective_user_permission_checked(eup1.id) )}
          {(start+2 < user_permissions.length) && view.render_checkbox(eup2.id, eup2.name, eup2.name, view.effective_user_permission_checked(eup2.id) )}
          {(start+3 < user_permissions.length) && view.render_checkbox(eup3.id, eup3.name, eup3.name, view.effective_user_permission_checked(eup3.id) )}
        </Row>
      );
    }
  }

  render_checkbox(id, name, label, checked)
  {
    const view = this;

    return (
      <Col md={3}>
        <div className="custom-control ">
         <input type="checkbox"
                className="custom-control-input"
                id={id}
                name={name}
                label={name}
                onChange={view.handle_user_permission_change}
                checked={checked}/>
         <label className="custom-control-label" htmlFor={id}>{label}</label>
       </div>
      </Col>
    );
  }

  render_user_group_radio(id, name, label, checked)
  {
    const view = this;

    const user_group = view.state.user_group;
    if(user_group != null)
    {
      var checked = (user_group.name===name);
      if(name==="Admin")
      {
        // checked = true;
      }

      return (
        <Col md={2}>
          <div className="custom-radio-button">
            <input type="radio"
                   id={id}
                   name={name}
                   value={id}
                    onChange={view.handle_user_group_radio_change}
                   checked={checked}/>
            <label htmlFor={id}> {label}
              <span>
              </span>
            </label>
        </div>
       </Col>
      );
    }
  }

  render()
  {
    const view = this;

    if(view.state.enable_render)
    {
      return (
        <React.Fragment>
          {view.render_user_permissions_editor()}
        </React.Fragment>
      );
    }
    return <div/>
  }
}


export default UserPermissionsView;
