
import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import FormControl from './FormControl'; // Importing the FormControl class
import ViewContainer from './ViewContainer';
import Appointments from '../components/Appointments';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class AppointmentsView  extends ViewContainer
{
    constructor(props)
    {
        super(props);

        const loggedin_user = props.app.state.loggedin_user;
        var client_id = null;
        if(loggedin_user != null)
        {
            client_id = loggedin_user.client_id;
        }

        this.state =
        {
            app: props.app,
            client_id: client_id,
            client: null
        };
    }

    async componentDidMount()
    {
        const view = this;

        await super.componentDidMount(()=>
        {
            const {client_id} = view.state;
            if(client_id != null)
            {
                view.get_client(client_id);
            }
        });
    }

    render_view()
    {
      const view = this;

      const {client} = view.state;



      if(client != null)
      {
        return (
            <React.Fragment>
            <Container className="view-container">
              <Appointments app={view.state.app}/>
            </Container>
            </ React.Fragment>
        );
      }
    }
}

export default AppointmentsView;
