import React from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import axios from 'axios';

class LeadEdit extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      // lead: { first_name: '', last_name: '', phone_number: '', email: '' },
      lead: props.lead,
      show_modal: false,
    };

    this.handle_input_change = this.handle_input_change.bind(this);
    this.handle_submit = this.handle_submit.bind(this);
    this.handle_delete = this.handle_delete.bind(this);
    this.fetch_lead_data = this.fetch_lead_data.bind(this);
    this.render_form_control = this.render_form_control.bind(this);
    this.render_delete_lead_button = this.render_delete_lead_button.bind(this);
    this.render_edit_modal = this.render_edit_modal.bind(this);
    this.render_view_details_modal = this.render_view_details_modal.bind(this);
  }

  componentDidMount()
  {
    // const lead_id = this.props.match.params.id;
    // this.fetch_lead_data(lead_id);
  }

  fetch_lead_data(lead_id)
  {
    axios.get(`/api/leads/${lead_id}`)
      .then(response =>
        this.setState({ lead: response.data })
      )
      .catch(error =>
      {
        console.error('Error fetching lead data', error);
      });
  }

  handle_input_change(event)
  {
    const { name, value } = event.target;
    this.setState(prevState =>
    {
      return {
        lead: { ...prevState.lead, [name]: value },
      };
    });
  }

  handle_submit(event)
  {
    event.preventDefault();

    /*
    if(this.props.match.params != null)
    {
      const lead_id = this.props.match.params.id;
      axios.put(`/api/leads/${lead_id}`, this.state.lead)
        .then(response =>
        {
          // Handle success (e.g., show a success message or redirect)
        })
        .catch(error =>
        {
          console.error('Error updating lead', error);
        });
    }
    */
  }

  handle_delete()
  {
    const lead_id = this.props.match.params.id;
    axios.delete(`/api/leads/${lead_id}`)
      .then(response =>
      {
        // Handle success (e.g., show a success message or redirect)
      })
      .catch(error =>
      {
        console.error('Error deleting lead', error);
      });
  }

  render_form_control(label, field_name, value)
  {
    const view = this;

    return(
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="text"
          name={field_name}
          value={value}
          onChange={this.handle_input_change}
        />
      </Form.Group>
    );
  }

  render_delete_lead_button()
  {
    const view = this;

    return (<Button variant="danger" onClick={() => this.setState({ show_modal: true })}>Delete Lead</Button>);
  }

  render_edit_modal()
  {
    const view = this;

    return (

      <React.Fragment>
        {view.render_delete_lead_button()}
        <Modal show={this.state.show_modal} onHide={() => this.setState({ show_modal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this lead?</Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button variant="secondary" onClick={() => this.setState({ show_modal: false })}>Cancel</Button>
              </Col>
              <Col>
                <Button variant="danger" onClick={this.handle_delete}>Delete</Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

  render_view_details_modal()
  {
    const view = this;

    return (
      <Modal show={this.state.show_modal} onHide={() => this.setState({ show_modal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Lead Details</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="secondary" onClick={() => this.setState({ show_modal: false })}>Close</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  render()
  {
    const view = this;

    const {first_name, last_name, phone_number, email, lead_status} = view.state.lead;

    return (
      <div>
        <Form onSubmit={() => this.setState({ show_modal: false })}>
          {view.render_form_control("First Name", "first_name", first_name)}
          {view.render_form_control("Last Name", "last_name", last_name)}
          {view.render_form_control("Phone Number", "phone_number", phone_number)}
          {view.render_form_control("Email", "email", email)}
          <Button type="submit" onClick={() => this.setState({ show_modal: false })}>Close</Button>
        </Form>

        {view.render_view_details_modal()}
      </div>
    );
  }
}

export default LeadEdit;
