
import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

import ViewContainer from './ViewContainer';
import LeadReactivationView from './LeadReactivationView';
import LeadReactivation from '../components/LeadReactivation';
import DemoWizardAssistantInstructions from '../components/DemoWizardAssistantInstructions';


import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import "./css/demo-wizard.css";

class DemoWizardView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      is_live: true,
      is_test: true,
      app: props.app,
    };
  }

  async componentDidMount()
  {
      const view = this;

      /*
      await super.componentDidMount(async()=>
      {
        await view.get_client_by_label(view.state.client_label, async()=>
        {
          await view.fetch_leads();
        });
      });
      */
  }

  render()
  {
      const view = this;

      const {client_label, is_test} = view.state;

      return (
          <React.Fragment>

          <div id="demo-wizard-wrapper">
                    <DemoWizardAssistantInstructions app={view.state.app}
                                                     role="Reactivation"
                                                     client_label={client_label}
                                                     assistant_instructions_loaded={view.assistant_instructions_loaded}
                                                     ref={view.assistant_instructions_ref}
                                                     allow_instructions_editing={false}
                                                     is_test={is_test} />
          </div>



        </React.Fragment>
      );
   }

};

export default DemoWizardView;
