
import React, { Component } from 'react';
import { Container, Form, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import AssistantInstructions from '../components/AssistantInstructions';
import FormControl from '../views/FormControl';
import ChatbotView from '../views/ChatbotView';

// import { GlobalHistory } from '../History';
// import getHistory from '../History';
import Axios from '../utils/Axios';
// import Cookies from 'js-cookie';
import base64 from 'base-64';
// import $ from "jquery";

class DemoWizardAssistantInstructions extends AssistantInstructions
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      ...this.state,
      step: 0,
      step_0_progress: 50,
      step_1_progress: 0,
      step_2_progress: 0,
      first_name_warning_class: "warning_invisible",
      invalid_link_warning_class: "warning_invisible"
    };

    this.handle_website_link_change = this.handle_website_link_change.bind(this);
    this.handle_website_link_send = this.handle_website_link_send.bind(this);

    this.on_create_assistant = this.on_create_assistant.bind(this);

    this.render_step_0 = this.render_step_0.bind(this);
    this.render_wizard_step = this.render_wizard_step.bind(this);
    this.render_wizard_steps = this.render_wizard_steps.bind(this);
    this.render_chat = this.render_chat.bind(this);
    this.render_progress_bar = this.render_progress_bar.bind(this);
  }

  async componentDidMount()
  {
      const view = this;

      // const assistant_json =
      // {
      //     user_first_name: null,
      //     name: "N/A",
      //     niche: "N/A",
      //     service: "N/A",
      //     first_question: "N/A",
      //     schedule_callback_question: "N/A",
      //     sales_bot_name: "N/A",
      //     client_website: "N/A",
      //     niche_question: "N/A",
      //     opening_hours: "N/A",
      //     conversion_link: "N/A"
      // }
      //
      // view.setState({assistant_json});
  }

  // No fetching existing instructions from the DB,
  // just use what the Demo Wizard has given us
  async fetch_or_create_assistant_instructions()
  {
    const view = this;

    const {client, role, client_label, assistant_instructions} = view.state;

    if(assistant_instructions != null)
    {
      view.state.instructions_loaded_callback(assistant_instructions);
    }
  }

  async create_or_update_assistant(on_success)
  {
    const _this = this;
  }

  async on_create_assistant(e)
  {
    e.preventDefault();

    const view = this;

    const { assistant_json  } = view.state;
    if(assistant_json != null)
    {
      if((assistant_json.user_first_name == null) || (assistant_json.user_first_name.length == 0))
      {
        view.setState({...view.state, first_name_warning_class: "warning_visible"});
        return;
      }

       const post_data = { assistant_json };

       const url = '/api/create_demo_assistant/';
       const response = await Axios.Post(url, post_data, async(response)=>
       {
         if(response != null)
         {
            const response_data = response.data;
            const have_valid_data = (response.status==200) ? true : false;

            // const dentist = view.get_client_lead("clearviewdental");
            await view.setState(
            {
               ...view.state,
               client_id: response_data.client_id,
               client_label: response_data.client_label,
               client_name: response_data.client_name,
               lead_id: response_data.lead_id,
               step_1_progress: 100,
               step: 2
            });
         }
       },
       (error)=>
       {
          console.log("NOOOO....");
       });
    }
  }

  async handle_change(event)
  {
    const view = this;

    const { name, value } = event.target;
    if((name != null) && (name.length > 0))
    {
      if((value != null) && (value.length > 0))
      {
        var assistant_json = view.state.assistant_json;
        assistant_json[name] = value;

        if((name == "user_first_name") && (value.length > 0))
        {
          view.setState({...view.state, first_name_warning_class: "warning_invisible"});
        }

        view.setState({assistant_json});
      }
    }
  }

  async handle_website_link_change(event)
  {
    const view = this;

    const { name, value } = event.target;
    if((name != null) && (name.length > 0))
    {
      if((value != null) && (value.length > 0))
      {
        // var assistant_instructions = _this.state.assistant_instructions;
        // assistant_instructions[name] = value;

        if((name == "website_link") && (value.length > 0))
        {
          view.setState({...view.state, invalid_link_warning_class: "warning_invisible"});
        }

        await view.setState({website_link: value});
      }
    }
  }

  check_url(url)
  {
    let given_url;
    try
    {
        given_url = new URL(url);
    }
    catch(error)
    {
      console.log ("error is", error);
      return false;
    }
    return true;
  }

  async handle_website_link_send(e)
  {
    const view = this;

    e.preventDefault();

    const {step, website_link} = view.state;

    if((website_link == null) || (website_link.length == 0) /*|| !view.check_url(website_link)*/)
    {
      view.setState({...view.state, invalid_link_warning_class: "warning_visible"});
      return;
    }

    const post_data=
    {
      url: website_link
    };

    const url = '/api/send_assistant_demo_link/';
    await Axios.Post(url, post_data, async(response)=>
    {
      if(response != null)
      {
          const have_valid_data = (response.status==200) ? true : false;

          const response_data = response.data;
          if((response_data != null) && have_valid_data)
          {
            var booking_link = website_link;
            if(booking_link[booking_link.length-1] != "/")
            {
              booking_link += "/bookings";
            }
            else
            {
              booking_link += "bookings";
            }

            const assistant_json =
            {
                user_first_name: null,
                name: response_data.CLIENT_NAME,
                niche: response_data.NICHE,
                service: response_data.SERVICE,
                first_question: response_data.FIRST_QUESTION,
                schedule_callback_question: response_data.SCHEDULE_CALLBACK_QUESTION,
                sales_bot_name: response_data.SALES_BOT_NAME,
                client_website: website_link, // use our version, the AI seems to fuck it up
                niche_question: response_data.NICHE_QUESTION,
                opening_hours: response_data.OPENING_HOURS,
                conversion_link: booking_link
            }
            console.log(assistant_json);

            await view.setState({...view.state,  assistant_json, step_0_progress: 100, step_1_progress: 50, step: 1});
          }
      }
    },
    (error)=>
    {
        view.setState({...view.state, invalid_link_warning_class: "warning_visible"});
    });
  }

  render_assistant_invalid_link_warning()
  {
      const view = this;

      var class_name = view.state.invalid_link_warning_class;
      return <h5 className={class_name}>Invalid link!</h5>;
  }

  render_assistant_first_name_warning()
  {
      const view = this;

      var class_name = view.state.first_name_warning_class;
      return <h5 className={class_name}>Please enter your first name to continue!</h5>;
  }

  render_assistant_instructions_edit_form()
  {
      const view = this;

      const { assistant_json, allow_instructions_editing, role } = view.state;
      if(assistant_json != null)
      {
        return (
                <div className="record-editor">
                  <h2>Reactivation Assistant Instructions</h2>

                  <Form onSubmit={view.on_create_assistant}>

                      <Row className="form-row">
                        <Col md={6} className="demo-wizard-col">
                            {view.render_form_control("Your First Name", "user_first_name", "text", null, "Please enter your first_name")}
                            {view.render_assistant_first_name_warning()}
                        </Col>
                      </Row>

                        <p className="edit-values-paragraph"><strong>If the following values are incorrect, please edit them.</strong></p>


                      <Row className="form-row">
                          <Col md={6} className="demo-wizard-col">
                              {view.render_form_control("Service", "service", "text", assistant_json.service)}
                          </Col>
                          <Col md={6} className="demo-wizard-col">
                              {view.render_form_control("Opening Hours", "opening_hours", "text", assistant_json.opening_hours)}
                          </Col>
                      </Row>
                      <Row className="form-row">
                          <Col md={12} className="demo-wizard-col">
                              {view.render_form_control("Question for lead", "niche_question", "text", assistant_json.niche_question)}
                          </Col>
                      </Row>

                      <Button variant="primary" className="float-right" type="submit">
                          Continue
                      </Button>
                      <br/>
                      <br/>
                      <br/>
                  </Form>
                </div>
          );
      }
  }

  render_step_0()
  {
    const view = this;

    const {step, website_link} = view.state;

    return (

        <React.Fragment>
          <div className="step_0_form_container">
            <h2>Step 1 - Enter your website link.</h2>
            <Form onSubmit={view.handle_website_link_send}>
                <Row>
                  <p className="website-link-instructions">Please enter the link to your website below:</p>
                </Row>
                <Row>
                    <Col md={10} className="demo-wizard-col">
                            <Form.Control
                                type="text"
                                name="website_link"
                                defaultValue={website_link}
                                onChange={this.handle_website_link_change}
                                disabled={false}/>
                            {view.render_assistant_invalid_link_warning()}
                    </Col>
                    <Col md={2}>
                      <Button variant="primary" className="send-weblink-button" type="submit">
                          Next
                      </Button>
                    </Col>
                </Row>
            </Form>
          </div>
        </React.Fragment>
    );
  }

  render_wizard_step()
  {
    const view = this;

    // return view.render_assistant_instructions_edit_form();

    const {step} = view.state;

    if(step == 0)
    {
      return view.render_step_0();
    }
    else
    if(step == 1)
    {
      return view.render_assistant_instructions_edit_form();
    }
  }

  render_wizard_steps()
  {
    const view = this;

    const {step_1_progress} = view.state;
    const step_1_complete = (step_1_progress == 100);

    if(!step_1_complete)
    {
      return(
        <React.Fragment>
          <h1 className="demo-wizard-title">Demo Wizard</h1>
          <div className="wizard-step-container">
              {view.render_wizard_step()}
              {view.render_progress_bar()}
          </div>
        </React.Fragment>
      )
    }
  }

  render_chat()
  {
    const view = this;

    const {step_1_progress, client_id, client_label, lead_id} = view.state;
    const step_1_complete = (step_1_progress == 100);

    if(step_1_complete)
    {
      return (<div className="demo-wizard-chat-container">
                <ChatbotView extra_classes="demo-chat" client_name={client_label} client_lead_id={lead_id} app={view.state.app}/>
              </div>
            )
    }
  }

  render_progress_bar()
  {
    const view = this;

    const {step_0_progress, step_1_progress, step_2_progress} = view.state;

    const step_0_complete = (step_0_progress == 100);
    const step_0_color = step_0_complete ? "success" : "warning";

    const step_1_complete = (step_1_progress == 100);
    var step_1_color = "transparent";
    if(step_0_complete)
    {
      step_1_color = step_1_complete ? "success" : "warning";
    }

    const step_2_complete = (step_2_progress == 100);
    var step_2_color = "transparent";
    if(step_2_complete)
    {
      step_2_color = step_1_complete ? "success" : "warning";
    }

    if(!step_1_complete)
    {
      // <h5>Progress</h5>

      return (
        <React.Fragment>
          <div className="demo-wizard-progress-bar-container">
            <ProgressBar className="demo-wizard-progress-bar">
            <ProgressBar striped variant={step_0_color} label={`${step_0_progress}%`} now={50} key={3} />
              <ProgressBar striped variant={step_1_color} label={`${step_1_progress}%`} now={50} key={1} />
            </ProgressBar>
          </div>
        </React.Fragment>
      );
    }
  }

  render()
  {
    const view = this;

    const {client} = view.state;
    // if(client != null)
    {
      return (
          <React.Fragment>

              {view.render_wizard_steps()}
              {view.render_chat()}
          </ React.Fragment>
      );
    }
  }

};

export default DemoWizardAssistantInstructions;
