import React, {Component} from 'react';
import { Container, Row, Col, Button, Tab, Tabs } from 'react-bootstrap';
import NavBar from "../components/NavBar";
import ChatbotView from "./ChatbotView";
import ChatBox from "../components/ChatBox";
import Axios from '../utils/Axios';
import ViewContainer from './ViewContainer';

class ChatbotDemosView extends ViewContainer
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      app: props.app,
      loggedin_user_id: props.loggedin_user_id,
      client_name: props.client_name
    };

    this.render_tabs = this.render_tabs.bind(this);
    this.get_client_lead_from_loggedin_user = this.get_client_lead_from_loggedin_user.bind(this);
    this.get_client_lead = this.get_client_lead.bind(this);
    this.render_tab = this.render_tab.bind(this);
  }

  async componentDidMount()
  {
    const _this = this;

    const client_labels = ["brighthomesrealty", "joesplumbing", "downtownauto", "clearviewdental"];
    const url = "/api/get_demo_client_leads/";
    await Axios.Post(url, client_labels, async(response)=>
    {
      const client_leads= response.data;
      await _this.setState({..._this.state, client_leads});
    });

    /*
    super.componentDidMount(async(loggedin_user)=>
    {
      _this.get_client_lead_from_loggedin_user(loggedin_user);
    });
    */
  }

  async get_client_lead_from_loggedin_user(loggedin_user)
  {
    const _this = this;

    if(loggedin_user != null)
    {
      await _this.setState({..._this.state, loggedin_user_id: loggedin_user.id}, async()=>
      {
        const {loggedin_user_id, client_name} = _this.state;
        if(loggedin_user_id != null)
        {
          const url = "/api/get_client_lead/" + client_name + "_" + loggedin_user_id;
          await Axios.Get(url, null, async(response)=>
          {
            const client_leads = response.data;
            await _this.setState({..._this.state, client_leads: client_leads});
          });
        }
      });
    }
  }

  get_client_lead(client_label)
  {
    const view = this;

    const {client_leads} = view.state;

    for(var i=0; i < client_leads.length; ++i)
    {
      if(client_leads[i].client ==client_label )
      {
        return client_leads[i];
      }
    }
    return null;
  }

  render_tab(title, client_lead, event_key)
  {
    const view = this;

    return (
        <Tab eventKey={event_key} title={title} className="chatbot-demo-tab">
              {client_lead &&<ChatBox extra_classes="tabbed-chat-box"
                                      is_live={true}
                                      client_name={client_lead.client}
                                      client_lead_id={client_lead.lead}
                                      chat_config={view.props.chat_config}
                                      app={view.state.app}/> }
        </Tab>
    );
  }

  render_tabs()
  {
    const view = this;

    const {client_leads} = view.state;
    if(client_leads != null)
    {
       const realestate = view.get_client_lead("brighthomesrealty");
       const plumbing = view.get_client_lead("joesplumbing");
       const cardealership = view.get_client_lead("downtownauto");
       const dentist = view.get_client_lead("clearviewdental");

       // <Tab eventKey="real-state" title="Real Estate" className="chatbot-demo-tab">
      //   {realestate && <ChatbotView client_name={realestate.client} client_lead_id={realestate.lead} app={view.state.app}/>}
      // </Tab>
       // <Tab eventKey="clearviewdental" title="Dentist" className="chatbot-demo-tab">
       //    {dentist && <ChatbotView client_name={dentist.client} client_lead_id={dentist.lead} app={view.state.app}/>}
       // </Tab>
       // <Tab eventKey="downtownauto" title="Car Dealership" className="chatbot-demo-tab">
       //    {<ChatbotView client_name={cardealership.client} client_lead_id={cardealership.lead} app={view.state.app}/>}
       // </Tab>
       // <Tab eventKey="joesplumbing" title="Plumbing" className="chatbot-demo-tab">
       //    {<ChatbotView  client_name={plumbing.client} client_lead_id={plumbing.lead} app={view.state.app}/>}
       // </Tab>

      return (

        <Tabs defaultActiveKey="real-state" id="uncontrolled-tab-example">
            {view.render_tab("Real Estate", realestate, "real-state")}
            {view.render_tab("Dentist", dentist, "clearviewdental")}
            {view.render_tab("Car Dealership", cardealership, "downtownauto")}
            {view.render_tab("Plumbing", plumbing, "joesplumbing")}
        </Tabs>
       );
     }
  }

  render()
  {
    const view = this;

    const {client_lead, client_leads, loggedin_user_id} = view.state;
    if(client_leads != null)
    {
      return (
        <div className="ChatDemosApp">

          <Container className="chatbot-demo-tabs-container">
          <h1 className="chatbot-demo-tabs-title">Chat Demos</h1>
            <Row className="chatbot-demo-tabs">
              <Col sm={2} md={2} lg={3}>
              </Col>
              <Col sm={8} md={8} lg={6} className="chatbot-demo-tabs-center-col">
                {view.render_tabs()}
              </Col>
              <Col sm={2} md={2} lg={3}>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

};

export default ChatbotDemosView;
