import React, {Component } from "react";
import Axios from "../utils/Axios";
import ASComponent from './ASComponent';
import Constants from "../Constants";

class SendMessage extends ASComponent
{
  constructor(props)
  {
    super(props);

    const _this = this;

    _this.state =
    {
      message: null,
      scroll: props.scroll,
      parent_view: props.parent_view
    };

    _this.on_send = _this.on_send.bind(this);
    _this.set_message = _this.set_message.bind(this);
  }

  async set_message(message)
  {
    const _this = this;

    _this.setState({..._this.state, message:""}, async()=>
    {
        _this.state.parent_view.set_do_scroll(true);
        _this.setState({..._this.state, message});
    })
  }

  async on_send(event)
  {
    const _this = this;

    event.preventDefault();

    const parent_view = _this.state.parent_view;
    if(parent_view != null)
    {
      const message = _this.state.message;
      if(message.trim().length > 0)
      {
        parent_view.on_new_user_message(message);
        await _this.set_message("");
     }
   }
  }

  render()
  {
    const _this = this;

    const message = _this.state.message;

    return (
      <form onSubmit={(event) => _this.on_send(event)} className="send-message">
        <label htmlFor="messageInput" hidden>
          Enter Message
        </label>
        <input
          id="messageInput"
          name="messageInput"
          type="text"
          className="form-input__input"
          placeholder="type message..."
          autoComplete="off"
          value={message || ""}
          onChange={async(e) => await _this.set_message(e.target.value)}
        />
        <button type="submit" >Send</button>
      </form>
    );
  }
};

export default SendMessage;
