import React, {Component} from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap';
import { BrowserRouter, useLocation } from "react-router-dom";
import { Route, Switch } from 'react-router-dom';

import NavBar from "./components/NavBar";
import ChatBox from "./components/ChatBox";
import Welcome from "./components/Welcome";

import HomeView from './views/HomeView';
import MainView from './views/MainView';
import SignupView from './views/SignupView';

import LoginView from './views/LoginView';
import PasswordResetRequestView from './views/PasswordResetRequestView';
import PasswordResetView from './views/PasswordResetView';


import ChatbotView from './views/ChatbotView';
import SMSDemoView from './views/SMSDemoView';
import DemoWizardView from './views/DemoWizardView';
import PermissionedView from './views/PermissionedView';

import ClientsView from './views/ClientsView';
import ClientView from './views/ClientView';
import AppointmentsView from './views/AppointmentsView';

import ProfileView from './views/ProfileView';
import UploadView from './views/UploadView';
import LeadsView from './views/LeadsView';
import LeadView from './views/LeadView';
import ReferrersView from './views/ReferrersView';
import ReferrerView from './views/ReferrerView';
import UsersView from './views/UsersView';
import UserView from './views/UserView';
import DemosView from './views/DemosView';
import ChatbotDemosView from './views/ChatbotDemosView';
import LeadQualificationConfigurationView from './views/LeadQualificationConfigurationView';
import LeadQualificationView from './views/LeadQualificationView';
import LeadReactivationView from './views/LeadReactivationView';
import Axios from './utils/Axios';

import { GlobalHistory } from './History';
import getHistory from './History';
import Cookies from 'js-cookie';

import "./App.css";

class App extends PermissionedView
{
  constructor(props)
  {
    super(props);

    var token = Cookies.get('token');
    if(token == "null")
    {
      token = null;
    }

    this.state =
    {
      token: token,
      app: this,
      user_is_admin: false,
      user_is_client_admin: false,
      user_is_referrer: false
    }

    this.logged_in = this.logged_in.bind(this);
    this.on_login = this.on_login.bind(this);
    this.logout = this.logout.bind(this);
    this.get_client_lead = this.get_client_lead.bind(this);


    // this.get_loggedin_user_group = this.get_loggedin_user_group.bind(this);
    // this.logged_in_admin = this.logged_in_admin.bind(this);
  }

  async componentDidMount()
  {
    super.componentDidMount();

    const app = this;

    const client_labels =
    [
      "brighthomesrealty",
      "joesplumbing",
      "downtownauto",
      "clearviewdental",
      "needlit",
      "axiosrecruitment",
      "oakridgedentalclinic"
    ];
    const url = "/api/get_demo_client_leads/";
    await Axios.Post(url, client_labels, async(response)=>
    {
      const client_leads= response.data;
      await app.setState({...app.state, client_leads});
    });
  }

  get_client_lead(client_label)
  {
    const app = this;

    const {client_leads} = app.state;
    if(client_leads != null)
    {
      for(var i=0; i < client_leads.length; ++i)
      {
        if(client_leads[i].client ==client_label )
        {
          return client_leads[i];
        }
      }
    }
    return null;
  }

  logged_in()
  {
    const token = Cookies.get("token");
    return ((token != null) && (token != "null")) ? true : false;
  }

  logout()
  {
    if(this.logged_in())
    {
      Cookies.set("token", null);
      const history = getHistory();
      history.push("/");
      // const location = useLocation();
      // location.reload();
    }
  }

  get_token()
  {
    return Cookies.get("token");
  }

  on_login(token, loggedin_user)
  {
    const app = this;

    app.setState({token, loggedin_user}, ()=>
    {
      Cookies.set("token", token);
      app.navigate_to_home();
    });
  }

  navigate_to_home()
  {
    const history = getHistory();
    history.push("/");
  }

  navigate_to_root()
  {
    const history = getHistory();
    history.push("/");
  }

  render_main_app()
  {
    // return <MainView app={this}/>
  }

  render_login_app()
  {
    // return <LoginView app={this} on_login={this.on_login} app={this}/>
  }

  render_home_app()
  {
    // return <HomeView app={this}/>
  }

  render_nav_bar()
  {
    /*
    if(this.logged_in())
    {
      const token = this.get_token();
      if((token != null) && (token.length > 0))
      {
        return (  <TopNavBar app={this}/> );
      }
    }
    return ( <LoggedOutNavBar app={this}/> );
    */
  }

  render()
  {
    var app = this;

    const {user_is_admin, user_is_client_admin, user_is_referrer, client_leads} = app.state;
    const is_admin_or_referrer = user_is_admin || user_is_referrer;
    const is_client_admin  = user_is_admin || user_is_client_admin || user_is_referrer

    var loggedin_user_id = null;
    if(app.state.loggedin_user != null)
    {
      loggedin_user_id = app.state.loggedin_user.id;
    }

    const chat_config=
    {
      chatbox_top_padding: "30px",
      chatbox_height: "100vh"
    };

    if(client_leads != null)
    {
      const realestate = app.get_client_lead("brighthomesrealty");
      const plumbing = app.get_client_lead("joesplumbing");
      const cardealership = app.get_client_lead("downtownauto");
      const dentist = app.get_client_lead("clearviewdental");
      const needlit = app.get_client_lead("needlit")
      const axiosrecruitment = app.get_client_lead("axiosrecruitment");
      const oakridge_dental = app.get_client_lead("oakridgedentalclinic");

  // <Route path="/login" render={(props) => <LoginView {...props} app={app} on_login={app.on_login}/>} />

  // client_id={"d91be78b-a916-11ef-b3a1-000c29103954"}

      return (
          <React.Fragment>
            <BrowserRouter>

               <GlobalHistory/>
               <link
               rel="stylesheet"
               href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
               integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
               crossOrigin="anonymous" />
                  <div className="page-container">
                    <Switch>

                      <Route exact path="/" render={(props) => <MainView {...props} on_login="/home" app={app} />} />

                      { is_admin_or_referrer && <Route exact path="/clients_demo" render={(props) => <ClientsView {...props} is_test={true} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/clients" render={(props) => <ClientsView {...props} is_test={false} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/client_demo/:id" render={(props) => <ClientView {...props} is_test={true} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/client/:id" render={(props) => <ClientView {...props} is_test={false} app={app} />} /> }

                      { user_is_admin && <Route exact path="/referrers" render={(props) => <ReferrersView {...props}  app={app} />} /> }
                      { user_is_admin && <Route exact path="/referrer/:id" render={(props) => <ReferrerView {...props}  app={app} />} /> }

                      { user_is_admin && <Route exact path="/users" render={(props) => <UsersView {...props}  app={app} />} /> }
                      { user_is_admin && <Route exact path="/user/:id" render={(props) => <UserView {...props}  app={app} />} /> }
                      { user_is_admin && <Route exact path="/demo_wizard" render={(props) => <DemoWizardView {...props} app={app} />} /> }

                      { is_client_admin && <Route exact path="/leads" render={(props) => <LeadsView {...props} clear_jobs={true} app={app} />} /> }
                      { is_client_admin && <Route exact path="/leads/:id" render={(props) => <LeadsView {...props} clear_jobs={true} app={app} />} /> }
                      { is_client_admin && <Route exact path="/lead/:id" render={(props) => <LeadView {...props} app={app} />} /> }

                      { is_client_admin && <Route exact path="/appointments" render={(props) => <AppointmentsView {...props} app={app} />} /> }
                      { is_client_admin && <Route exact path="/profile" render={(props) => <ProfileView {...props} app={app} />} /> }
                      { is_client_admin && <Route exact path="/upload" render={(props) => <UploadView {...props} app={app} />} /> }


                      { is_admin_or_referrer && <Route exact path="/lead_reactivation_demo/:id" render={(props) => <LeadReactivationView {...props} is_test={true} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/lead_reactivation/:id" render={(props) => <LeadReactivationView {...props} is_test={false} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/test/" render={(props) => <DemosView {...props} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/lead_qualification_configuration_demo/:id" render={(props) => <LeadQualificationConfigurationView {...props} is_test={true} clear_jobs={true} app={app} client_id={"d91be78b-a916-11ef-b3a1-000c29103954"} />} /> }
                      { is_admin_or_referrer && <Route exact path="/lead_qualification_configuration/:id" render={(props) => <LeadQualificationConfigurationView {...props} is_test={false}  clear_jobs={true} app={app} client_id={"d91be78b-a916-11ef-b3a1-000c29103954"} />} /> }
                      { is_admin_or_referrer && <Route exact path="/lead_qualification_demo/:id" render={(props) => <LeadQualificationView {...props} app={app} is_test={true}  />} /> }
                      { is_admin_or_referrer && <Route exact path="/lead_qualification/:id" render={(props) => <LeadQualificationView {...props} app={app} is_test={false} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chatbot_demos" render={(props) => <ChatbotDemosView {...props} loggedin_user_id={loggedin_user_id}app={app} />} /> }


                      { is_admin_or_referrer && <Route exact path="/chat_job_candidate" render={(props) => <ChatbotView {...props}  chat_config={chat_config} client_name={axiosrecruitment.client} client_lead_id={axiosrecruitment.lead} loggedin_user_id={loggedin_user_id} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chat_plumbing" render={(props) => <ChatbotView {...props} chat_config={chat_config} client_name={plumbing.client} client_lead_id={plumbing.lead}  app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/sms_plumbing" render={(props) => <SMSDemoView {...props} client_name={plumbing.client} client_lead_id={plumbing.lead} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chat_realestate" render={(props) => <ChatbotView {...props} chat_config={chat_config} client_name={realestate.client} client_lead_id={realestate.lead} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/sms_realestate" render={(props) => <SMSDemoView {...props} client_name={realestate.client} client_lead_id={realestate.lead} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chat_needl" render={(props) => <ChatbotView {...props} chat_config={chat_config} client_name={needlit.client} client_lead_id={needlit.lead} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/sms_needl" render={(props) => <SMSDemoView {...props} client_name={needlit.client} client_lead_id={needlit.lead} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chat_downtownauto" render={(props) => <ChatbotView {...props} chat_config={chat_config} client_name={cardealership.client} client_lead_id={cardealership.lead} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/sms_downtownauto" render={(props) => <SMSDemoView {...props} client_name={cardealership.client} client_lead_id={cardealership.lead} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chat_dentist" render={(props) => <ChatbotView {...props} chat_config={chat_config} client_name={dentist.client} client_lead_id={dentist.lead} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/sms_dentist" render={(props) => <SMSDemoView {...props} client_name={dentist.client} client_lead_id={dentist.lead} app={app} />} /> }

                      { is_admin_or_referrer && <Route exact path="/chat_oakridge_dental" render={(props) => <ChatbotView {...props} chat_config={chat_config} client_name={oakridge_dental.client} client_lead_id={oakridge_dental.lead} app={app} />} /> }
                      { is_admin_or_referrer && <Route exact path="/sms_oakridge_dental" render={(props) => <SMSDemoView {...props} client_name={oakridge_dental.client} client_lead_id={oakridge_dental.lead} app={app} />} /> }

                      <Route exact path="/home" render={(props) => <HomeView {...props} app={app} />} />

                      <Route path="/login" render={(props) => <LoginView {...props} app={app} on_login={app.on_login}/>} />
                      <Route path="/password_reset_request" render={(props) => <PasswordResetRequestView {...props} app={app} />} />
                      <Route path="/password_reset/" render={(props) => <PasswordResetView {...props} app={app} />} />


                      <Route path="/signup" render={(props) => <SignupView {...props} app={app} />} />

                    </Switch>
                  </div>

              </BrowserRouter>
          </React.Fragment>
      );
    }
  }

}

export default App;
