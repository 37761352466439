
import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

class AppointmentModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            name: null,
            email: null,
            website: null,
            conversion_link: null,
            phone: null,
            opening_hours: null,
            is_submitting: false,
            error: null,

            appointment: props.appointment,
            show_modal: props.show_modal,
            parent_view: props.parent_view,
            onClose: props.on_close,
            is_test: props.is_test
        };

        this.handle_close = this.handle_close.bind(this);
        this.handle_input_change = this.handle_input_change.bind(this);
        this.handle_submit = this.handle_submit.bind(this);

        this.validate_form_field = this.validate_form_field.bind(this);
        this.validate_form_email_field = this.validate_form_email_field.bind(this);
        this.validate_form = this.validate_form.bind(this);

        this.render_form_control = this.render_form_control.bind(this);
    }

    componentDidMount()
    {
      const view = this;
      view.setState({...view.state, show_modal: true});
    }

    async validate_form_field(field)
    {
        const view = this;
        if((field == null) || (field.length == 0))
        {
          await view.setState({...view.state,  error: 'All fields are required.' });
          return false;
        }
        return true;
    }

    validate_form_email_field(email)
    {
        const view = this;

        if(email != null)
        {
          if(view.validate_form_field(email))
          {
            return (email.indexOf('@') != -1) && (email.indexOf('.') != -1);
          }
        }
        return false;
    }

    validate_form()
    {
      const view = this;

      const
      {
        first_name, last_name, user_email,
        name, email, phone, address, website, conversion_link, opening_hours
      } = view.state;

      return view.validate_form_field(first_name)&&
             view.validate_form_field(opening_hours);
    }

    handle_input_change(event)
    {
        const { name, value } = event.target;
        this.setState({ ...this.state,  [name]: value, error: null });
    }

    async handle_submit(event)
    {
        const view = this;

        event.preventDefault();

        if(!view.validate_form())
        {
          return;
        }

        // const { name,  email, website, conversion_link, opening_hours, phone, is_test  } = view.state;

        const
        {
          first_name, last_name, user_email,
          name, email, phone, address, website, conversion_link, opening_hours, is_test
        } = view.state;

        await view.setState({ ...view.state, is_submitting: true, error: null });

        try
        {
            const client = { name, email, website, conversion_link, opening_hours, phone, is_test };

            var client_label = name;
            client_label = client_label.replaceAll(" ", "");
            client_label = client_label.replaceAll("-", "");
            client_label = client_label.replaceAll("_", "");
            client_label = client_label.replaceAll("'", "");
            client_label = client_label.replaceAll("@", "");
            client_label = client_label.replaceAll(".", "");
            client_label = client_label.replaceAll(",", "");

            const client_user = { first_name, last_name, email: user_email, user_group_name: "ClientAdmin", client_label };
            view.props.parent_view.create_client(client, client_user,);
            view.handle_close(); // Close the modal after submission
        }
        catch (error)
        {
            view.setState({ ...view.state, error: 'An error occurred. Please try again later.' });
        }
        finally
        {
            view.setState({ ...view.state, is_submitting: false });
        }
    }

    handle_close()
    {
      const view = this;
      view.setState({show_modal: false}, ()=>
      {
          view.state.parent_view.on_close_modal();
      })
    }

    render_form_control(label, field_name, value, type)
    {
      const view = this;

      const form_control_type = (type != null) ? type : "text";
      return(
          <Row className="form-row">
            <Form.Group controlId={field_name} style={{width: "90%"}}>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                type={form_control_type}
                name={field_name}
                default_value={value}
                onChange={this.handle_input_change}
              />
            </Form.Group>
        </Row>
      );
    }

    render()
    {
        const view = this;

        const { onClose } = this.props;

        const
        {
          first_name, last_name, user_email,
          name, email, phone, address, website, conversion_link,
          opening_hours, is_submitting, error, show_modal,
        } = this.state;

        return (
            <Modal show={show_modal} onHide={view.handle_close}>
                <Modal.Header closeButton>
                    <Modal.Title>Appointment</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form onSubmit={this.handle_submit}>
                        <h3 className="ll-h3">User</h3>
                        {view.render_form_control("Client User First Name", "first_name", first_name)}

                        <Button variant="primary" type="submit" disabled={is_submitting}>
                          Save
                        </Button>
                        <Button className="modal-cancel-button" variant="primary" type="submit" disabled={is_submitting} onClick={view.handle_close}>
                         Cancel
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AppointmentModal;
