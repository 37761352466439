
import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import FormControl from './FormControl'; // Importing the FormControl class
import ViewContainer from './ViewContainer';
import LeadTable from '../components/LeadTable';
import AssistantInstructions from '../components/AssistantInstructions';
import CSVUpload from '../components/CSVUpload';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class ClientView  extends ViewContainer
{
    constructor(props)
    {
        super(props);

        const client_label = props.match.params.id;

        this.state =
        {
            app: props.app,
            client_label: client_label,
            client: null
        };

        // this.fetch_client = this.fetch_client.bind(this);
        this.navigate_to_leads_view = this.navigate_to_leads_view.bind(this);
        this.navigate_to_reactivation_view = this.navigate_to_reactivation_view.bind(this);
        this.navigate_to_qualification_view = this.navigate_to_qualification_view.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.handle_submit = this.handle_submit.bind(this);
        this.render_client_edit_form = this.render_client_edit_form.bind(this);
    }

    async componentDidMount()
    {
        const view = this;

        await super.componentDidMount(()=>
        {
          view.get_client_by_label(view.state.client_label);
        });
    }


    // Handle change in input fields
    async handle_change(event)
    {
      const _this = this;

      const { name, value } = event.target;
      this.setState(prevState => ({
          client: {
              ...prevState.client,
              [name]: value
          }
      }));
    }

    async handle_submit(event)
    {
      const _this = this;

      event.preventDefault();

      const client = _this.state.client;

      const post_data=
      {
        client_id: client.id,
        name: client.name,
        email: client.email,
        label: client.label,
        website: client.website,
        phone: client.phone,
        conversion_link: client.conversion_link,
      };

      const url = '/api/client';
      const response = await Axios.Put(url, post_data, (response)=>
      {
        console.log(response);
      });
    }

    navigate_to_leads_view()
    {
      const view = this;

      const {client} = view.state;
      if(client != null)
      {
        const history = getHistory();
        history.push("/leads/" + client.label)
      }
    }

    navigate_to_reactivation_view()
    {
      const view = this;

      const {client} = view.state;
      if(client != null)
      {
        const history = getHistory();
        if(view.state.client.is_test)
        {
          history.push("/lead_reactivation_demo/" + client.label)
        }
        else
        {
          history.push("/lead_reactivation/" + client.label)
        }
      }
    }

    navigate_to_qualification_view()
    {
      const view = this;

      const {client} = view.state;
      if(client != null)
      {
        const history = getHistory();
        if(view.state.client.is_test)
        {
          history.push("/lead_qualification_configuration_demo/" + client.label)
        }
        else
        {
          history.push("/lead_qualification_configuration/" + client.label)
        }
      }
    }

    render_form_control(label, name, type, value, disabled)
    {
      return (
        <FormControl
            id={name}
            label={label}
            name={name}
            default_value={value}
            type={type}
            disabled={disabled}
            onChange={this.handle_change}/>
      );
    }

    render_client_edit_form()
    {
        const view = this;

        const { client } = view.state;
        if(client != null)
        {
          const title = (client.is_test) ? "Client (Test)" : "Client";

          return (
                  <div className="record-editor">
                    <h2>{title}</h2>
                    <Form onSubmit={this.handle_submit}>
                        <Row className="form-row">
                            <Col md={6}>
                                {view.render_form_control("Name", "name", "text", client.name)}
                            </Col>
                            <Col md={6}>
                                {view.render_form_control("Phone", "phone", "text", client.phone)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={6}>
                              {view.render_form_control("Email", "email", "email", client.email)}
                            </Col>
                            <Col md={6}>
                                  {view.render_form_control("Website", "website", "text", client.website)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={6}>
                              {view.render_form_control("API Key", "api_key", "api_key", client.api_key, true)}
                            </Col>
                        </Row>

                        <Button variant="primary" onClick={()=>view.navigate_to_reactivation_view()}>
                            Reactivation
                        </Button>

                        <Button variant="primary" onClick={()=>view.navigate_to_qualification_view()}>
                            Qualification
                        </Button>

                        <Button variant="primary" className="float-right" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                  </div>

            );
        }
    }

    render_view()
    {
      const view = this;

      const {client} = view.state;

      // <CSVUpload client_label={client_label}/>
      // <AssistantInstructions client_label={client_label}/>

      if(client != null)
      {
        return (
            <React.Fragment>
            <Container className="view-container">
              {view.render_client_edit_form()}
              <CSVUpload client_label={client.label} is_test={client.is_test} />
              <LeadTable fetch_interval={1000} client_label={client.label}/>
            </Container>
            </ React.Fragment>
        );
      }
    }
}

export default ClientView;
