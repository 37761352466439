
import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import FormControl from './FormControl'; // Importing the FormControl class
import ViewContainer from './ViewContainer';
import CSVUpload from '../components/CSVUpload';
import AssistantInstructions from '../components/AssistantInstructions';
import JobDescription from '../components/JobDescription';
import LeadQualificationConfiguration from '../components/LeadQualificationConfiguration';
import base64 from 'base-64';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class LeadQualificationConfigurationView  extends ViewContainer
{
    constructor(props)
    {
      super(props);

      const client_label = props.match.params.id;

      this.state =
      {
          app: props.app,
          client_label: client_label,
          client: null,
          have_jobdescription: false,
          have_leads: false,
          force_is_test: props.force_is_test,
          is_test: props.is_test,
          clear_jobs: props.clear_jobs
      };

      this.navigate_to_lead_qualification_view = this.navigate_to_lead_qualification_view.bind(this);
      this.fetch_leads = this.fetch_leads.bind(this);
      this.handle_continue = this.handle_continue.bind(this);

      this.assistant_instructions_loaded = this.assistant_instructions_loaded.bind(this);
      this.job_description_edit_callback = this.job_description_edit_callback.bind(this);
      this.job_description_file_change_callback = this.job_description_file_change_callback.bind(this);
      this.configuration_complete = this.configuration_complete.bind(this);

      this.has_jobdesc = this.has_jobdesc.bind(this);
      this.has_leads = this.has_leads.bind(this);
      this.assistant_is_ready = this.assistant_is_ready.bind(this);

      this.assistant_instructions_ref = React.createRef();
      this.job_description_ref = React.createRef();
    }

    async componentDidMount()
    {
        const view = this;

        await super.componentDidMount(async()=>
        {
          // view.fetch_client();
          await view.get_client_by_label(view.state.client_label, async()=>
          {
            // Get the leads, if they exist...
            await view.fetch_leads();
          });
        });
    }

    async assistant_instructions_loaded(assistant_instructions)
    {
      const view = this;

      const job_desc = view.job_description_ref.current;
      if(job_desc != null)
      {
        if(assistant_instructions.instructions_extra_post != null)
        {
          const job_description = base64.decode(assistant_instructions.instructions_extra_post);
          await view.setState({...view.state, job_description});
          await job_desc.setState({...job_desc.state, job_description});
        }
      }
    }

    async job_description_edit_callback(job_description)
    {
      const view = this;

      await view.setState({...view.state, job_description}, async()=>
      {
        const job_desc = view.job_description_ref.current;
        if(job_desc != null)
        {
            await job_desc.setState({...job_desc.state, job_description});
        }

        const assistant_instructions_ctl = view.assistant_instructions_ref.current;
        if(assistant_instructions_ctl != null)
        {
            await assistant_instructions_ctl.setState({...assistant_instructions_ctl.state, instructions_extra_post:job_description});
        }
      });
    }

    async job_description_file_change_callback(job_description)
    {
      const view = this;

      await view.job_description_edit_callback(job_description);
    }

    navigate_to_lead_qualification_view(client_job_id)
    {
      const view = this;

      const {client} = view.state;
      if(client != null)
      {
        const history = getHistory();
        if(view.state.is_test)
        {
          history.push("/lead_qualification_demo/" + client.label);
        }
        else
        {
          history.push("/lead_qualification/" + client.label);
        }
      }
    }

    async fetch_leads(on_success)
    {
      const view = this;

      const { loggedin_user, client_label, pagination, pagination_limit } = this.state;

      const url = "/api/get_leads/" + view.state.client_label;

      const post_data =
      {
        client_label: client_label,
        index: 0,
        limit: 10
      };

      await Axios.Post(url, post_data, async(response)=>
      {
        const array_data = response.data;
        await view.setState({...view.state, have_leads: true, leads: response.data});

        if(on_success != null)
        {
          on_success(response.data);
        }
      });
    }

    async handle_continue(event)
    {
      const view = this;

      event.preventDefault();

      const {client, job_desc_filename, job_description, csv_filename, csv_data, is_test} = view.state;

      const assistant_instructions_comp = view.assistant_instructions_ref.current;
      if((assistant_instructions_comp != null) && (job_description != null))
      {
        if(client != null)
        {
          const post_data=
          {
            client_id: client.id,
            job_desc_filename,
            job_desc_data: job_description,
            csv_filename,
            csv_data,
            is_test,
            clear_jobs: view.props.clear_jobs
          };

          await assistant_instructions_comp.create_or_update_assistant(async()=>
          {
            const url = '/api/client_job_prequalification';
            const response = await Axios.Post(url, post_data, (response)=>
            {
              console.log(response);
              const client_job_id = response.data.id;
              view.navigate_to_lead_qualification_view(client_job_id);
            });
          });
        }
      }
    }

    has_jobdesc()
    {
      const view = this;

      return view.state.have_jobdescription || view.state.job_description;
    }

    has_leads()
    {
      const view = this;
      return view.state.have_leads;
    }

    assistant_is_ready()
    {
      const view = this;

      const assistant_instructions_comp = view.assistant_instructions_ref.current;
      if(assistant_instructions_comp != null)
      {
        return assistant_instructions_comp.state.assistant_ready;
      }
      return false;
    }

    configuration_complete()
    {
        const view = this;
        return (view.state.have_leads) && (view.state.have_jobdescription);
    }

    render_view()
    {
      const view = this;
      const {client_label, force_is_test, is_test, clear_jobs, job_description} = view.state;

      // job_description={job_description}

      return (
          <React.Fragment>
          <Container className="view-container">
            <LeadQualificationConfiguration client_label={client_label} clear_jobs={clear_jobs} is_test={is_test} parent_view={view}/>

            <JobDescription client_label={client_label}
                            is_test={is_test}
                            job_description_edit_callback={view.job_description_edit_callback}
                            file_change_callback={view.job_description_file_change_callback}
                            ref={view.job_description_ref}/>

            <AssistantInstructions role="Qualification"
                                   client_label={client_label}
                                   is_test={is_test}
                                   assistant_instructions_loaded={view.assistant_instructions_loaded}
                                   ref={view.assistant_instructions_ref} />
          </Container>
          </ React.Fragment>
      );
    }
}

export default LeadQualificationConfigurationView;
