import React, {Component } from "react";
import Message from "./Message";
import Axios from "../utils/Axios";
import ASComponent from './ASComponent';
import Constants from "../Constants";
import $ from "jquery";

class MessagesView extends ASComponent
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      is_live: props.is_live,
      client_lead_id: props.client_lead_id,
      messages: props.messages,
      polling: false,
      polling_interval_id: -1,
      run_state: "",
      messages: (props.messages != null) ? props.messages : [],
      parent_view: props.parent_view
    };

    this.start_polling = this.start_polling.bind(this);
    this.poll = this.poll.bind(this);
    this.polling_loop = this.polling_loop.bind(this);
    this.on_polling_error = this.on_polling_error.bind(this);

    this.add_fake_message = this.add_fake_message.bind(this);
    this.fetch_messages = this.fetch_messages.bind(this);
    this.scroll_to_bottom = this.scroll_to_bottom.bind(this);
    this.render_message = this.render_message.bind(this);

    this.scroll = React.createRef();
  }

  componentDidUpdate ()
  {
     this.scroll_to_bottom();
  }

  async componentDidMount()
  {
    const _this = this;

    const parent_view = _this.state.parent_view;
    if((parent_view != null) && (_this.state.messages.length == 0))
    {
      if(!parent_view.state.conversation_started)
      {
        const url_params = _this.state.client_lead_id;
        const url = '/api/get_first_question/' + url_params;

        var messages = [];
        await Axios.Get('/api/get_first_question/', url_params, async(response)=>
        {
          if(response != null)
          {
            const first_question = response.data;
            await _this.add_fake_message("Assistant", first_question.message);
            // console.log("first_question: ", first_question);
          }
        });
      }
    }

     window.scrollTo(0, 0);
  }

  async componentWillUnmount()
  {
    const _this = this;

    console.log("Clearing polling interval...");
    clearInterval(_this.state.polling_interval_id);
    _this.setState({..._this.state, polling_interval_id: null});
  }

  // Adds a message to state.messages so that we can at
  // least display the message until OpenAI is aware of it
  async add_fake_message(participant, fake_message, placeholder)
  {
    const _this = this;

    const is_user = (participant == "User");

    var messages = _this.state.messages;
    const message=
    {
      id: "1234",
      name: participant,
      text:
      {
        value: fake_message
      },
      placeholder: !is_user && placeholder
    };

    messages.push(message);
    if(is_user)
    {
      const last_user_message = is_user ? fake_message : null;
      await _this.setState({..._this.state, messages, last_user_message: last_user_message});
    }
    else
    {
      await _this.setState({..._this.state, messages});
    }
  }

  async start_polling()
  {
      const _this = this;

      const {parent_view, client_lead_id} = _this.state;
      if(client_lead_id != null)
      {
        if(!_this.state.polling)
        {
          const current_messages = _this.state.messages;
          const num_messages = current_messages.length;
          await _this.poll(null, parent_view, async(messages)=>
          {
            if( messages.length == current_messages)
            {
               // Keep polling for new messages;
               await _this.polling_loop(parent_view);
            }
          },
          async (error)=>
          {
            console.log("POLLING ERROR!");

            // Keep polling for new messages;
            await _this.polling_loop(parent_view);
          });
        }
      }
  };

  // This is so we can start the polling loop on poll success or error.
  async polling_loop(parent_view)
  {
    const _this = this;

    console.log("STARTING POLLING LOOP...");

    const interval_id = setInterval(async()=>
    {
      if(!_this.state.polling)
      {
        await _this.setState({..._this.state, polling: true, polling_interval_id: interval_id}, async()=>
        {
          console.log("start_polling - started polling!");
          await _this.poll(interval_id, parent_view);
        });
      }
    }, 1000);
  }

  // This is so we can poll once, without having to start an interval
  async poll(interval_id, parent_view, on_success, on_error)
  {
    const _this = this;

    const messages_list = this.scroll.current;
    if(messages_list != null)
    {
         try
         {
             const current_messages = _this.state.messages;
             await _this.fetch_messages(async(messages)=>
             {
               const num_messages = current_messages.length;
               var last_current_message = current_messages[current_messages.length-1];
               var last_new_message = current_messages[current_messages.length-1];
               const last_user_message = _this.state.last_user_message;
               const num_new_messages = messages.length;
               // console.log("num_new_messages: ", num_new_messages);
               if(num_new_messages > 0)
               {
                 // Handle case where there is only one Assistant message received,
                 // but OpenAI has not yet received our first user message
                 if(last_user_message != null)
                 {
                     const fake_msg =
                     {
                       name: "User",
                       text: { value: _this.state.last_user_message },
                       updatedAt: messages[0].updatedAt,
                       placeholder: false
                     }

                     if(num_new_messages == 1)
                     {
                       messages.push(fake_msg);
                     }
                 }

                 if(num_new_messages < num_messages)
                 {
                   const pending_msg =
                   {
                     name: "Assistant",
                     role: "assistant",
                     text: { value: "." },
                     updatedAt: new Date().getTime(),
                     placeholder: true
                   }
                    messages.push(pending_msg);
                 }

                 await _this.setState({..._this.state, messages: [],  do_scroll: false}, async()=>
                 {
                     await _this.setState({..._this.state,
                       messages: messages,
                       polling: false,
                       polling_interval_id: interval_id,
                       messages_list: messages_list,
                       last_user_message: null
                     }, async()=>
                     {
                       if(interval_id != null)
                       {
                         clearInterval(interval_id);
                       }

                       if((_this.scroll != null) &&
                          (_this.scroll.current != null) &&
                          (parent_view.state.do_scroll))
                       {
                          _this.state.messages_list.scrollTop = _this.state.messages_list.scrollHeight;
                         parent_view.set_do_scroll(false);
                       }

                       parent_view.on_fetched_messages(messages);
                       if(on_success != null)
                       {
                          on_success(messages);
                       }
                     });
                 });
               }
             },
             async(error)=>
             {
               await _this.on_polling_error(interval_id, parent_view, error);
             });
       }
       catch(error)
       {
         console.log("CAUGHT EXCEPTION!");
         await _this.on_polling_error(interval_id, parent_view, error);
       }
    }
  }

  async on_polling_error(interval_id, parent_view, error)
  {
    const _this = this;

    clearInterval(interval_id);
    await _this.setState({..._this.state, polling: false}, async()=>
    {
        await _this.polling_loop(parent_view);
    });
  }

  async fetch_messages(on_success, on_error)
  {
    const _this = this;

    const client_lead_id = _this.state.client_lead_id;
    if(client_lead_id != null)
    {
       try
       {
          await Axios.Get('/api/get_messages/', client_lead_id, (response)=>
          {
             var messages = response.data;
             messages = messages.sort(
               (a, b) =>
                 new Date(a.created_at).getTime() -
                 new Date(b.created_at).getTime()
             );

              messages.forEach((message) =>
              {
                if(message.role == "assistant")
                {
                  message.name = "Assistant";
                }
                else
                {
                  message.name = "User";
                }

                if(message.content != null)
                {
                  if(message.content.length > 0)
                  {
                    message.text = message.content[0].text;
                  }
                }
              });

            if(messages.length == 0)
            {
              // console.log("Messages empty!");
            }

            on_success(messages);
        },
        (error)=>
        {
          console.log("AXIOS ERANU!");
          on_error(error);
        });
       }
       catch (error)
       {
         console.log("error", error);
         on_error(error);
       }
     }
 };

  scroll_to_bottom()
  {
    const _this = this;

    if(_this.state.messages_list != null)
    {
      _this.state.messages_list.scrollTop = _this.state.messages_list.scrollHeight;
    }
  }

  render_message(message, messages, key)
  {
    const _this = this;

    return (
        <Message key={key} message={message} placeholder={message.placeholder}/>
    );
  }

  render()
  {
    const _this = this;

    const {messages} = _this.state;
    return (
      <div ref={_this.scroll} className="messages-wrapper" >
        {messages.map((message, index) => (
            _this.render_message(message, messages, index)
        ))}
      </div>
    );
  }
};


export default MessagesView;
