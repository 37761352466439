
import React, { Component } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import FormControl from './FormControl'; // Importing the FormControl class
import ViewContainer from './ViewContainer';
import CSVUpload from '../components/CSVUpload';
import ChatBox from '../components/ChatBox';

import { GlobalHistory } from '../History';
import getHistory from '../History';

import Axios from '../utils/Axios';
import Cookies from 'js-cookie';

import '../App.css';

class LeadView  extends ViewContainer
{
    constructor(props)
    {
      super(props);

      const lead_id = props.match.params.id;

      this.state =
      {
          app: props.app,
          lead_id: lead_id,
          lead: null
      };

      this.get_lead = this.get_lead.bind(this);
      this.get_latest_conversation = this.get_latest_conversation.bind(this);
      this.navigate_to_leads_view = this.navigate_to_leads_view.bind(this);
      this.handle_change = this.handle_change.bind(this);
      this.handle_save_latest_conversation = this.handle_save_latest_conversation.bind(this);

      this.render_lead_edit_form = this.render_lead_edit_form.bind(this);
      this.render_latest_conversation = this.render_latest_conversation.bind(this);
    }

    async componentDidMount()
    {
        const view = this;

        await super.componentDidMount(()=>
        {
          view.get_lead();
          // view.intervalId = setInterval(async()=>
          // {
            // view.get_lead();
          // }, 1000);
        });
    }

    async componentWillUnmount()
    {
      const view = this;
      clearInterval(view.intervalId);
    }

    async get_lead()
    {
      const view = this;

      const { lead_id } = this.state;

      const url = "/api/lead/" + lead_id;
      await Axios.Get(url, null, async(lead)=>
      {
        if(lead.data != null)
        {
          await Axios.Get('/api/client/', lead.data.client_id, async(client)=>
          {
            if(client != null)
            {
              // view.intervalId = setInterval(async()=>
              //{
                // await view.setState({...view.state, lead: null /*, client: null*/}, async()=>
                // {
                  await view.setState({...view.state, lead: lead.data , client: client.data},
                    async()=>
                    {
                      await view.get_latest_conversation();
                    });
                // });
              //}, 1000);
            }
          });
        }
      });
    }

    async get_latest_conversation()
    {
      const view = this;

      const {client, lead} = view.state;

      if((client != null) && (lead != null))
      {
        if(lead.lead_status == "Qualified")
        {
            clearInterval(view.intervalId);
        }

        await Axios.Get("/api/latest_conversation/" + lead.id, null, async(conversation)=>
        {
          if(conversation.data != null)
          {
            var messages = [];
            const db_messages = conversation.data.db_messages;
            if(db_messages != null)
            {
              for(var i=0; i < db_messages.length; ++i)
              {
                const db_message = db_messages[i];

                const name = (db_message.direction == 'outbound') ? "Assistant" : lead.first_name;
                const message =
                {
                  id: db_message.id,
                  name: name,
                  text:
                  {
                      value: db_message.message_body
                  }
                }

                messages.push(message);
              }

              conversation.data.messages = messages;
            }

            await view.setState({...view.state, conversation_data: null}, async()=>
            {
              await view.setState({...view.state, conversation_data: conversation.data});
            });

          }
          else
          {
            console.log("No conversation data!");
          }
        });
      }
    }

    // Handle change in input fields
    async handle_change(event)
    {
      const _this = this;

      const { name, value } = event.target;
      this.setState(prevState => ({
          lead: {
              ...prevState.lead,
              [name]: value
          }
      }));
    }

    navigate_to_leads_view(client_id)
    {
      const history = getHistory();
      history.push("/leads/" + client_id)
    }

    handle_save_latest_conversation(event)
    {
      const view = this;

      event.preventDefault();

      const {lead_id, lead, client, conversation_data} = view.state;
      if(conversation_data != null)
      {
        const messages = conversation_data.messages;
        var file_messages = ""; // "Assistant," + lead.first_name;
        for(var i=0; i < messages.length; ++i)
        {
          const message = messages[i];
          // file_messages += "\n";

          if(i % 2)
          {
            file_messages += "\nLead: ";
            file_messages += message.text.value;
          }
          else
          {
            file_messages += "\nAssistant: ";
            file_messages += message.text.value;
          }
        }

        console.log("file_messages:\n ", file_messages);
        const blob = new Blob([file_messages], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = lead.first_name + "-" + lead.last_name + "-qualification.txt";
        link.href = url;
        link.click();
      }
    }

    render_form_control(label, name, type, value)
    {
      return (
        <FormControl
            label={label}
            name={name}
            default_value={value}
            type={type}
            disabled={true}
            onChange={this.handle_change}/>
      );
    }

    render_lead_edit_form()
    {
        const view = this;

        const { lead } = view.state;
        if(lead != null)
        {
          return (
                  <div className="record-editor">
                    <h2>Lead</h2>
                    <Form onSubmit={this.handle_submit}>
                        <Row className="form-row">
                            <Col md={6}>
                                {view.render_form_control("First Name", "first_name", "text", lead.first_name)}
                            </Col>
                            <Col md={6}>
                                {view.render_form_control("Last Name", "last_name", "text", lead.last_name)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={6}>
                              {view.render_form_control("Email", "email", "email", lead.email)}
                            </Col>
                            <Col md={6}>
                                {view.render_form_control("Phone", "phone", "text", lead.phone_number)}
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={6}>
                              {view.render_form_control("Status", "status", "text", lead.lead_status)}
                            </Col>
                        </Row>
                    </Form>
                  </div>

            );
        }
    }

    render_latest_conversation()
    {
      const view = this;

      const {lead_id, lead, client, conversation_data} = view.state;
      if(conversation_data != null)
      {
        return (
          <div className="record-editor">
            <Row>
              <Col>
                <h2>Latest Conversation</h2>
              </Col>
              <Col>
                <Button variant="primary"
                        className="float-right"
                        type="submit"
                        onClick={view.handle_save_latest_conversation}>
                    Save Conversation
                </Button>
              </Col>
            </Row>
            <div className="latest-conversation">
              <ChatBox
                       is_live={false}
                       messages={conversation_data.messages}
                       client_name={client.label}
                       client_lead_id={lead_id}
                       chat_config={view.props.chat_config}/>
              </div>
          </div>
        );
      }
    }

    render_view()
    {
      const view = this;

      const {lead_id, client, conversation_data} = view.state;
      if((lead_id != null) && (client != null))
      {
        return (
            <React.Fragment>
            <Container className="view-container">
              {view.render_lead_edit_form()}
              {view.render_latest_conversation()}
            </Container>
            </ React.Fragment>
        );
      }
    }
}

export default LeadView;
