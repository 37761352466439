import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, FormControl, Label } from "react-bootstrap";
import ASButton from '../components/ASButton.js';
import Cookies from 'js-cookie';
import Axios from '../utils/Axios';
import ViewContainer from './ViewContainer';

import wordmark from '../assets/menu-wordmark.png';

class LoginView extends ViewContainer
{
    constructor(props)
    {
      super(props);

      this.state = {
        email: "",
        password: "",
        app: props.app
      }

      this.on_login = this.on_login.bind(this);
      this.on_email_change = this.on_email_change.bind(this);
      this.on_password_change = this.on_password_change.bind(this);
      this.validate_form = this.validate_form.bind(this);
    }

    on_login(e)
    {
      e.preventDefault();
      e.stopPropagation();

      var view = this;

      // TODO: Email validation here

      const data =
      {
        email: this.state.email,
        password: this.state.password
      }

      Axios.Post('/api/account_token/', data, async(response)=>
      {
          const token = response.data.token;
          await view.get_logged_in_user(async(token, user)=>
          {
              view.state.app.on_login(token, user);
          });
      });
    }

    on_email_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, email: e.target.value});
    }

    on_password_change(e)
    {
      e.preventDefault();
      this.setState({...this.state, password: e.target.value});
    }

    validate_form()
    {
        return (this.state.email.length > 0) && (this.state.password.length > 0);
    }

    render_view()
    {
      const app = this.props.app;

      if(app.logged_in())
      {
        return app.render_home_view();
      }
    }

    render()
    {
      const app = this.props.app;

      const view = this;
      const class_names = "toolbar " + this.props.class_names;

      if(app.logged_in())
      {
        app.navigate_to_home();
        return;
      }

      return (
          <Container className="absolute-center is-responsive login-container" style={{marginTop: "20%"}}>
            <Row>
              <Col md={3}></Col>
              <Col lg={6} md={6} xs={12} sm={12}  className="login-center-column">
              <img src={wordmark} className="signin-logo"/>
              <Form onSubmit={view.on_login} className={"login-form"}>
                <FormGroup controlId="email" >
                  <Form.Label>Email</Form.Label>
                  <FormControl
                    autoFocus
                    type="email"
                    value={this.state.email}
                    onChange={this.on_email_change}
                  />
                </FormGroup>
                <FormGroup controlId="password" >
                  <Form.Label>Password</Form.Label>
                  <FormControl
                    value={this.state.password}
                    onChange={this.on_password_change}
                    type="password"
                  />
                </FormGroup>
                <a className="password_reset_request" href="/password_reset_request">forgot your password?</a>
                <Button block disabled={!this.validate_form()} type="submit" className="as_button">
                  Login
                </Button>
              </Form>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Container>
      );
    }

}

export default LoginView;
